import React from "react";
import Toggle from "../Toggle/Toggle";

export default function CookieItem(props) {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div className="cookie-item">
        <span onClick={props.onClick} className="cookie-item-head">
          <svg
            width="12"
            height="12"
            viewBox="0 0 94 64"
            fill="none"
            transform={`rotate(${props.isActive ? "180" : "90"})`}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M91 47L77 61L47 31L17 61L3 47L47 3L91 47Z"
              fill="#9E9E9E"
              stroke="#9E9E9E"
              stroke-width="4"
            />
          </svg>
          {props.label}
        </span>
        <Toggle
          id={props.id}
          checked={props.checked}
          name={props.name}
          onChange={props.onChange}
        />
      </div>
      {props.isActive && (
        <div className="cookie-text-content">{props.content || ""}</div>
      )}
    </div>
  );
}
