import React, { useState, useRef, useEffect } from "react";
import "./customdropdown.scss";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useSelector } from "react-redux";

const dropdownOptions = [
  { index: 1, value: "Random" },
  { index: 2, value: "Popularity" },
  { index: 3, value: "Newest" },
  { index: 4, value: "Oldest" },
];

const CustomDropdown = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(null);
  const [activeOptionIndex, setActiveOptionIndex] = useState(1);
  const darkMode = useSelector((state) => state.settings.darkMode);
  const dropRef = useRef();

  useEffect(() => {
    let isClickOutside = (e) => {
      // let dropdownElem = document.getElementById("custom-dropdown");
      if (menuOpen && dropRef.current && !dropRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", isClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", isClickOutside);
    };
  }, [menuOpen]);

  const _onOptionClick = (option, index) => {
    const previousIndex = activeOptionIndex;
    const upIcon = document.getElementById("icon-up");
    const downIcon = document.getElementById("icon-down");
    props.handleOnClick(option);
    if (previousIndex > index) {
      upIcon.style.color = darkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)";
      setTimeout(() => {
        upIcon.style.color = darkMode
          ? "rgba(255,255,255,0.6)"
          : "rgba(0,0,0,0.6)";
      }, 700);
    }
    if (previousIndex < index) {
      downIcon.style.color = darkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)";
      setTimeout(() => {
        downIcon.style.color = darkMode
          ? "rgba(255,255,255,0.6)"
          : "rgba(0,0,0,0.6)";
      }, 700);
    }
    setActiveOption(option);
    setActiveOptionIndex(index);
    const optionList = document.getElementById("option-list");
    optionList.style.opacity = "0";
    optionList.style.transform = `translateY(-${index * 43}px)`;
    setTimeout(() => {
      setMenuOpen(false);
    }, 500);
  };

  React.useEffect(() => {
    if (activeOptionIndex) {
      const optionList = document.getElementById("option-list");
      if (optionList) {
        optionList.style.transform = `translateY(-${activeOptionIndex * 43}px)`;
      }
    }
  }, [menuOpen]);

  React.useEffect(() => {
    const upIcon = document.getElementById("icon-up");
    const downIcon = document.getElementById("icon-down");
    if (upIcon && downIcon) {
      upIcon.style.color = darkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)";
      downIcon.style.color = darkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)";
    }
  }, [darkMode]);

  React.useEffect(() => {
    if (props.sortByValue && props.sortByValue !== "Sort By") {
      let sortByIndex = [...props.dropdownOptions].filter(
        (data, index) =>
          data.name.toLowerCase() === props.sortByValue.toLowerCase()
      );
      setActiveOption(props.sortByValue);
      sortByIndex &&
        sortByIndex[0] &&
        setActiveOptionIndex(sortByIndex[0].index);
    }
  }, [props.sortByValue]);

  const onMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div
        id="custom-dropdown"
        ref={dropRef}
        style={{
          zIndex: 99999999999999999999999,
          minWidth: window.innerWidth > 600 ? "140px" : "120px",
          marginRight: "3px",
          overflow: "hidden",
        }}
      >
        <div className={`${darkMode ? "selector-dark" : "selector"}`}>
          <div id="select-field" onClick={onMenuOpen}>
            <p className="active-text">
              {!activeOption ? props.dropdownName : activeOption}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <RiArrowDropUpLine
                id="icon-up"
                style={{
                  fontSize: "17px",
                  padding: "0px",
                  marginBottom: "-3px",
                  opacity: 0.6,
                }}
              />
              <RiArrowDropDownLine
                id="icon-down"
                style={{
                  fontSize: "17px",
                  padding: "0px",
                  marginTop: "-3px",
                  opacity: 0.6,
                }}
              />
            </div>
          </div>
          {menuOpen ? (
            <ul id="option-list" style={{ zIndex: 1000000000000 }}>
              {props.dropdownOptions.map((data, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => _onOptionClick(data.name, data.index)}
                    className="option"
                  >
                    <p>{data.name}</p>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CustomDropdown;
