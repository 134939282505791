import React, { useState } from "react"; // { useState, useEffect, useRef }

import GLOBALS from "../../constants";
import Axios from "axios";
//from packages
import urlRegex from "url-regex";
import PropTypes from "prop-types";
import store from "../../redux/store";
import { connect, useSelector } from "react-redux";
import { Popup } from "semantic-ui-react";
import { NavLink, withRouter } from "react-router-dom";

//components
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";

//hooks

//apis
import { getSimilarPosts } from "../../api/api";

//types
import {
  SET_FAVOURITE_POSTS,
  SET_AUTH_FAVOURITE_POSTS,
} from "../../redux/types";

function SimilarPosts(props) {
  const [similarPosts, setSimilarPosts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [timeKey, setTimeKey] = React.useState(new Date());
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [favouritesLoader, setFavouritesLoader] = useState(false);

  const notify = (message) =>
    toast.dark(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
    });

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setLoading(true);
    if (props.ID) {
      getSimilarPosts(`per_page=3&post_id=${props.ID}&paged=1`, signal)
        .then((res) => {
          setSimilarPosts(res.json);
          setLoading(false);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
    return () => controller.abort();
  }, [props.ID]);

  const _favourite = (postData) => {
    // e.preventDefault();
    if (localStorage.getItem("userToken")) {
      setFavouritesLoader(true);
      let formData = new FormData();
      formData.append("post_id", postData.ID);

      return Axios({
        url: `${GLOBALS.SERVER_URL}wp-json/ng/v1/favorite`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        },
        data: formData,
      })
        .then((res) => {
          let savedFavs = store.getState().favourite.authFavPosts || [];
          let foundPost = savedFavs.find((fav) => fav.ID === postData.ID);
          if (res.data.type === "unfavorite") {
            savedFavs.splice(savedFavs.indexOf(foundPost), 1);
            // savedFavs.push({
            // 	...postData,
            // 	favorited: false
            // });
          } else if (res.data.type === "favorite") {
            savedFavs.unshift({ ...postData, favorited: true });
          }
          store.dispatch({
            type: SET_AUTH_FAVOURITE_POSTS,
            payload: savedFavs,
          });
          notify(res.data.message);
          setFavouritesLoader(false);
        })
        .catch((err) => {
          setFavouritesLoader(false);
        });
    } else {
      const savedFavs = props.favourite.favPosts || [];
      const foundPost = savedFavs.find((fav) => fav.ID === postData.ID);
      if (foundPost) {
        savedFavs.splice(savedFavs.indexOf(foundPost), 1);
        notify("Removed from Favorites");
      } else {
        savedFavs.unshift(postData);
        notify("Added to Favorites");
      }
      store.dispatch({ type: SET_FAVOURITE_POSTS, payload: savedFavs });
      setTimeKey(new Date());
    }
  };

  const _handleBrokenLink = (ev) => {
    ev.currentTarget.src =
      "https://www.comparemovies.info/static/favicon-16x16.png";
  };

  const SimilarPostsList =
    similarPosts.length > 0 ? (
      similarPosts.map((instance, index) => {
        let favouriteIsActive = false;
        if (localStorage.getItem("userToken")) {
          if (
            (store.getState().favourite.authFavPosts || []).find(
              (fav) => fav.ID === instance.ID
            )
          ) {
            favouriteIsActive = true;
          }
        } else {
          if (
            (props.favourite.favPosts || []).find(
              (fav) => fav.ID === instance.ID
            )
          ) {
            favouriteIsActive = true;
          }
        }

        let tooltip = "Tag";
        for (let tag of instance.tags || []) {
          if (tag.url) {
            var tagSlug = tag.slug;
            tooltip = tag.name;
            var tagIcon = tag.url;
            break;
          }
        }

        const customClasses = instance.categories
          ? instance.categories.reduce((accumulator, currentValue) => {
              return accumulator + `category-${currentValue} `;
            }, "")
          : "";
        const customClassForLink = instance.categories
          ? instance.categories.reduce((accumulator, currentValue) => {
              return accumulator + `category-link-${currentValue} `;
            }, "")
          : "";

        let myColor = "#007ad9";

        try {
          const categoryOfPost = props.categories.filter(
            (cat) => cat.term_id === instance.categories[0]
          )[0];
          myColor = categoryOfPost.color;
        } catch (e) {}

        return (
          <div
            key={index}
            className={`p-col-12 p-lg-12 similar-single-post ${customClasses}`}
            id={`post-${instance.ID}`}
            style={{
              padding: 0,
              // borderStyle: "solid"
            }}
          >
            <div
              className="p-text-center card summary similar-popup-bg"
              style={{
                display: "flex",
                padding: "9px",
                boxShadow: "none",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                {instance.tags.length < 1 ? (
                  <div style={{ height: 20, width: 26 }}></div>
                ) : (
                  <Popup
                    trigger={
                      <img
                        src={
                          tagIcon
                            ? tagIcon
                            : "https://www.comparemovies.info/static/favicon-16x16.png"
                        }
                        alt="thumbnail icon"
                        className="thumbnail-card"
                        style={{ height: "50px", cursor: "pointer" }}
                        onClick={() => {
                          store.dispatch({
                            type: "SET_POST_CARD_DATA_MODAL",
                            payload: {
                              postCardModal: false,
                              activePostCardModal: null,
                            },
                          });
                          props.history.push(`/tags/${tagSlug}`);
                        }}
                      />
                    }
                    content={tooltip}
                    inverted
                    position="bottom center"
                    size="tiny"
                  />
                )}
              </div>

              <div
                style={{
                  lineHeight: "20px",
                  paddingLeft: "10px",
                }}
              >
                <div
                  className={`similar-post-content ${
                    instance.post_excerpt ? "post-card-link" : ""
                  } `}
                  dangerouslySetInnerHTML={{
                    __html: (instance.post_excerpt || "").replace(
                      urlRegex({ strict: false }),
                      function (url) {
                        if (instance.favicon && props.linkIcons) {
                          return (
                            '</><span class="favIconClassSimillarPosts"><img src="' +
                            instance.favicon +
                            '" onerror=' +
                            { _handleBrokenLink } +
                            ' /></span><a href="' +
                            url +
                            `"target="_blank" class= inpost-link ${customClassForLink}"` +
                            `id="link-${instance.ID}" style="color:${
                              myColor || "#007ad9"
                            } " >` +
                            url
                              .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                              .split("/")[0] +
                            "</a></>"
                          );
                        } else {
                          return (
                            '<a href="' +
                            url +
                            `"target="_blank" class = "inpost-link ${customClassForLink}"` +
                            `id="link-${instance.ID}" style="color:${
                              myColor || "#007ad9"
                            }">` +
                            url
                              .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                              .split("/")[0] +
                            "</a>"
                          );
                        }
                        // return (
                        // 	'<a href="' +
                        // 	url +
                        // 	`"target="_blank" class = "inpost-link ${customClassForLink}"` +
                        // 	`id="link-${instance.ID}" style="color:${myColor || "#007ad9"}">` +
                        // 	url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0] +
                        // 	"</a>"
                        // );
                      }
                    ),
                  }}
                ></div>
                <div
                  className="similar-post-title"
                  style={{ marginTop: "5px" }}
                >
                  {props.forModal ? (
                    <span
                      onClick={() => {
                        // store.dispatch({ type: "SET_POST_CARD_DATA_MODAL", payload: { postCardModal: false, activePostCardModal: null } });
                        // store.dispatch({ type: "SET_POST_CARD_DATA_MODAL", payload: { postCardModal: true, activePostCardModal: null } });
                        store.getState().world.modalRef.current.scrollTop = 0;
                        store.dispatch({
                          type: "SET_POST_MODAL_DATA",
                          payload: instance,
                        });
                      }}
                      style={
                        darkMode
                          ? { color: "#fff", cursor: "pointer" }
                          : { color: "#000", cursor: "pointer" }
                      }
                    >
                      {instance.post_title}
                    </span>
                  ) : (
                    <NavLink
                      to={`/link?L=${instance.ID}`}
                      // className="post-title"
                      style={darkMode ? { color: "#fff" } : { color: "#000" }}
                    >
                      {instance.post_title}
                    </NavLink>
                  )}

                  {/* <hr /> */}
                  {/* <span
										dangerouslySetInnerHTML={{
											__html: (props.post_excerpt || "").replace(
												urlRegex({
													strict: false
												}),
												function(url) {
													if (instance.favicon && props.linkIcons) {
														return (
															'</><span class="favIconClass"><img src="' +
															instance.favicon +
															'" /></span><a href="' +
															url +
															`"target="_blank" class= ${props.linkClass} ${props.customClassForLink}"` +
															`id="link-${instance.ID}" style="color:${props.categoryColor || props.color || "#007ad9"} " >` +
															url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0] +
															"</a></>"
														);
													} else {
														return (
															'<a href="' +
															url +
															`"target="_blank" class= ${props.linkClass} ${props.customClassForLink}"` +
															`id="link-${instance.ID}" style="color:${props.categoryColor || props.color || "#007ad9"} " >` +
															url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0] +
															"</a>"
														);
													}
												}
											)
										}}
										className="detail content-details"
									></span> */}
                </div>
              </div>
              <div
                className="fav-similar"
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span
                  style={{ float: "right" }}
                  onClick={() => _favourite(instance)}
                >
                  <i
                    className={`flaticon-star ${
                      favouriteIsActive ? "active" : ""
                    }`}
                    style={{ fontSize: "1.5em" }}
                  ></i>
                </span>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div
        style={{
          textAlign: "center",
          paddingTop: "30px",
          paddingBottom: "20px",
        }}
      >
        No Similar Links Found
      </div>
    );

  return (
    <div className="p-col-12 p-lg-12 ">
      <div
        className="p-col-12 p-lg-12"
        style={{
          textAlign: "center",
          paddingTop: "0",
          color: darkMode ? "white" : "black",
        }}
      >
        <b>Similar Links</b>
      </div>

      {!loading ? (
        SimilarPostsList
      ) : (
        <div>
          <Loader loadingMore={true} />
        </div>
      )}
    </div>
  );
}

SimilarPosts.propTypes = {
  favourite: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  favourite: state.favourite,
  categories: state.categories.categories,
});

export default withRouter(connect(mapStateToProps)(SimilarPosts));
