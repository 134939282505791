import React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { Label } from "semantic-ui-react";
import { useSelector } from "react-redux";
import SlidingMenu from "../../components/SlidingMenu";

function InfoBar(props) {
  const [activeRoute, setActiveRoute] = React.useState("");
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [isInfoPage, setIsInfoPage] = React.useState(false);
  const infoRoutes = [
    "/info",
    "/faq",
    "/terms",
    "/privacy",
    "/cookies",
    "/disclaimer",
    "/eula",
    "/use",
  ];

  React.useEffect(() => {
    setActiveRoute(props.pathname);
    if (infoRoutes.includes(props.pathname)) {
      setIsInfoPage(true);
    } else {
      setIsInfoPage(false);
    }
  }, [props.pathname]);

  const Items = [
    {
      id: 0,
      name: "ABOUT",
      href: "/info",
    },
    {
      id: 1,
      name: "FAQ",
      href: "/faq",
    },
    {
      id: 2,
      name: "TERMS",
      href: "/terms",
    },
    {
      id: 3,
      name: "PRIVACY",
      href: "/privacy",
    },
    {
      id: 4,
      name: "COOKIES",
      href: "/cookies",
    },
    {
      id: 5,
      name: "DISCLAIMER",
      href: "/disclaimer",
    },
    {
      id: 6,
      name: "EULA",
      href: "/eula",
    },
    {
      id: 7,
      name: "USE",
      href: "/use",
    },
  ];

  return (
    <div
      className={`tags-bar ${
        window.innerWidth > 1024
          ? props.iconMode
            ? "DesktopTagsBar TagsBarOnIconMode"
            : "DesktopTagsBar"
          : "MobileTagsBar"
      } ${isInfoPage ? "info-page-tagsbar" : ""}`}
      style={{ width: "100%", zIndex: 1 }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <SlidingMenu items={Items} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    tagsByIconPosts: state.world.tagsByIconPosts,
  };
};

export default connect(mapStateToProps, null)(withRouter(InfoBar));
