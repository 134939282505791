import React from "react";
import { FiEdit } from "react-icons/fi";
import isEmpty from "../../utils/isEmpty";

function SubmissionTable(props) {
  const { submissionData, loading, onLinkClick, tableType } = props;
  return (
    <div
      className={`submission-table-container${
        props.isMobile ? "-mobile" : ""
      }__subs-table`}
      style={{ flexDirection: "column" }}
    >
      <table>
        <tr className="headerr">
          <th className="date">DATE</th>
          <th className="link">LINK</th>
          <th className="status">TYPE</th>
          <th className="points">POINTS</th>
        </tr>
        <tbody className="table-body">
          {submissionData &&
            !loading &&
            submissionData.map((sd, ind) => {
              let submDate = sd.date ? sd.date.split(" ")[0] : "yyyy-mm-dd";
              let reverseDate = submDate.split("-").reverse();

              let temp = reverseDate[0];
              reverseDate[0] = reverseDate[1];
              reverseDate[1] = temp;

              let finDate = reverseDate.join("-");
              return (
                <tr
                  onClick={() => {
                    if (tableType === "Upvotes" || sd.status === "deleted") {
                      return;
                    }
                    onLinkClick(sd.post_id);
                  }}
                >
                  <td className="date">{finDate ? finDate : ""}</td>
                  <td className="link">
                    {sd.link
                      ? sd.link.includes("https://")
                        ? sd.link.replace("https://", "")
                        : sd.link
                      : "-"}
                    {tableType === "Upvotes" ? null : sd.status ===
                      "deleted" ? null : (
                      <FiEdit />
                    )}
                  </td>
                  <td className="status">{sd.status ? sd.status : "none"}</td>
                  <td className="points">{sd.points ? sd.points : "+0"}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {loading && (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px 0px 20px 0px",
          }}
        >
          <b>Loading...</b>
        </div>
      )}
      {isEmpty(submissionData) && !loading && (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px 0px 20px 0px",
          }}
        >
          <b>No Any Item</b>
        </div>
      )}
    </div>
  );
}

export default SubmissionTable;
