import React from "react";
import "./toggle.scss";

function Toggle(props) {
  return (
    <div className={`toggle-switch`} id={props.name}>
      <input
        className="toggle-switch-input"
        name={props.name}
        type="checkbox"
        checked={props.checked}
        id={`switch-${props.id}`}
        key={props.name}
        onChange={props.onChange}
      />
      <label id="switch-label" for={`switch-${props.id}`}>
        <span>l</span>
        <span>r</span>
      </label>
    </div>
  );
}

export default Toggle;
