import React, { useState } from "react";

//from packages
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Popup } from "semantic-ui-react";
import { Button } from "primereact/button";
import { withRouter } from "react-router-dom";
import { popWindow } from "../../utils/manageShare";
import { isEmpty } from "lodash";

function ListItem(props) {
  const [listData, setListData] = React.useState({});
  const [activesList, setActivesList] = useState({
    share: false,
  });
  const [linkCopied, setLinkCopied] = useState(false);
  const darkMode = useSelector((state) => state.settings.darkMode);

  React.useEffect(() => {
    setListData(props.listData);
  }, [props.listData]);

  //destructure post data
  var { term_id, name, slug, date, image, thumbnail, groups } = listData;

  let groupString = "";
  if (!isEmpty(groups)) {
    groups.forEach((element, ind) => {
      if (ind !== groups.length - 1) {
        groupString += element.name + ", ";
      } else {
        groupString += element.name;
      }
    });
  }

  const _pop = (type) => {
    popWindow(type, name, `https://${window.location.host}/lists/${slug}`);
  };

  const _toggleActive = (field) => {
    setActivesList({
      ...activesList,
      [field]: !activesList[field],
    });
  };

  const _onShareClick = () => {
    if (navigator.share || navigator.canShare) {
      try {
        navigator
          .share({
            title: "PikiLinks",
            url: `https://${window.location.host}/lists/${listData.slug}`,
          })
          .then(() => {
            // console.log("Hooray! Your content was shared to tha world");
          })
          .catch((err) => {
            // alert("navigator.share catch", err);
          });
      } catch (error) {
        console.log(`Oops! Link couldnt be shared: ${error}`);
      }
    } else {
      setActivesList({ ...activesList, share: true });
      setLinkCopied(false);
    }
  };

  const inListView = props.settings.listMode;

  return (
    <>
      {inListView ? <div className="p-md-1 p-lg-2"></div> : null}
      <div
        className={`p-col-12 ${
          inListView ? "p-md-10 p-lg-8" : "p-lg-6"
        } list-item`}
        id={`list-${term_id}`}
        style={{ padding: 0 }}
      >
        <div className={`p-col-12 p-lg-12 `}>
          <div className="card summary ">
            <div className="p-grid">
              {thumbnail ? (
                <div className="p-col-2">
                  <Button className="for-tooltip p-button-link">
                    <img
                      src={thumbnail}
                      className="thumbnail-card"
                      style={{ width: "100%" }}
                    />
                  </Button>
                </div>
              ) : null}

              <div
                className={`p-col-${thumbnail ? 10 : 12}`}
                style={{ height: "80px" }}
              >
                <div className="p-grid">
                  <div className="p-col-12">
                    <span
                      className=" list-item-title"
                      onClick={() => props.history.push(`lists/${slug}`)}
                    >
                      {name}
                    </span>
                    <hr />
                  </div>
                </div>
              </div>
              <div
                className="p-col-4"
                style={{
                  paddingBottom: 0,
                  textAlign: "left",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {groupString}
              </div>
              <div
                className="p-col-4"
                style={{ paddingBottom: 0, textAlign: "center" }}
              >
                <span>
                  <span
                    style={{
                      paddingRight: "4px",
                      fontSize: "0.9em",
                      position: "relative",
                      bottom: "5px",
                      textAlign: "right",
                      color: darkMode ? "#9e9e9e" : "balck",
                    }}
                  >
                    {new Date(date).toDateString()}
                  </span>
                  <i className="pi pi-clock " style={{ fontSize: "1.5em" }}></i>
                </span>
              </div>
              <div
                className="p-col-4"
                style={{ paddingBottom: 0, textAlign: "right" }}
              >
                <Popup
                  trigger={
                    <span>
                      <Popup
                        trigger={
                          <span
                          // onClick={() => _toggleActive("share")}
                          >
                            <i
                              className={`glyph-icon flaticon-share ${
                                activesList.share ? "active" : ""
                              } shareIcon share-btn`}
                              style={{
                                cursor: "pointer",
                                color: darkMode ? "#9e9e9e" : "black",
                              }}
                            >
                              Share
                            </i>
                          </span>
                        }
                        className="tags-popup share-popup"
                        on="click"
                        basic
                        open={activesList.share}
                        onClose={() =>
                          setActivesList({ ...activesList, share: false })
                        }
                        onOpen={() => {
                          _onShareClick();
                        }}
                      >
                        <span
                          className="hideShareOption"
                          style={{
                            backgroundColor: darkMode ? "#2E3035" : "#EDF0F5",
                            color: "#8A8A8A",
                          }}
                        >
                          <span
                            className="icon-con-social"
                            onClick={() => _pop("linkedin")}
                          >
                            <i className="flaticon-linkedin"></i> &nbsp;
                            <b>LinkedIn</b>
                          </span>
                          <span
                            className="icon-con-social"
                            onClick={() => _pop("reddit")}
                          >
                            <i className="flaticon-reddit-1"></i> &nbsp;
                            <b>Reddit</b>
                          </span>
                          <span
                            className="icon-con-social"
                            onClick={() => _pop("twitter")}
                          >
                            <i className="flaticon-twitter"></i> &nbsp;
                            <b>Twitter</b>
                          </span>
                          <span
                            className="icon-con-social"
                            onClick={() => _pop("facebook")}
                          >
                            <i className="flaticon-facebook"></i> &nbsp;
                            <b>Facebook</b>
                          </span>

                          <span
                            className="icon-con-social"
                            onClick={() => _pop("messenger")}
                          >
                            <i className="flaticon-messenger"></i> &nbsp;
                            <b>Messenger</b>
                          </span>
                          <span
                            className="icon-con-social"
                            onClick={() => _pop("gmail")}
                          >
                            <i className="flaticon-email-1"></i> &nbsp;
                            <b>Email</b>
                          </span>
                          <span
                            className="icon-con-social"
                            onClick={() => {
                              setLinkCopied(true);
                              _pop("link");
                            }}
                          >
                            <i className="flaticon-link"></i> &nbsp;
                            <b>{linkCopied ? "Copied" : "Copy Link"}</b>
                          </span>
                        </span>
                      </Popup>
                    </span>
                  }
                  content={"Share"}
                  inverted
                  disabled={activesList.share}
                  position="bottom center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {inListView ? <div className="p-md-1 p-lg-2"></div> : null}
    </>
  );
}

ListItem.propTypes = {
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  settings: state.settings,
});

export default withRouter(connect(mapStateToProps)(ListItem));
