import React, { useEffect, useState } from "react";
import { Modal, Label } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import megaphone from "../../assets/img/megaphone.png";
import CookieItem from "./CookieItem";
import { NavLink } from "react-router-dom";
import { getCookieConsent, saveCookieConsent } from "../../api/api";
import { toast } from "react-toastify";

export default function CookieSettings(props) {
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [cookies, setCookies] = useState({
    necessary: false,
    perfomance: false,
    adv: false,
  });
  const [activeContent, setActiveContent] = useState(null);
  const [loading, setLoading] = useState(false);

  const notify = (message) =>
    toast.dark(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
    });

  const _onCookieChange = (e) => {
    setCookies({ ...cookies, [e.target.name]: e.target.checked });
  };

  const getCookieConsentData = () => {
    getCookieConsent("")
      .then((res) => {
        setCookies({
          necessary: res.json.strictly_necessary_cookie || false,
          perfomance: res.json.performance_and_analytics_cookie || false,
          adv: res.json.advertisement_and_targeting_cookie || false,
        });

        localStorage.setItem(
          "cookie-consent",
          JSON.stringify({
            necessary: res.json.strictly_necessary_cookie || false,
            perfomance: res.json.performance_and_analytics_cookie || false,
            adv: res.json.advertisement_and_targeting_cookie || false,
          })
        );
      })
      .catch((err) => {
        // handle error
      });
  };

  useEffect(() => {
    getCookieConsentData();
  }, []);

  const _saveSettings = () => {
    if (loading) {
      return;
    }

    const requestJson = {
      strictly_necessary_cookie: cookies.necessary,
      performance_and_analytics_cookie: cookies.perfomance,
      advertisement_and_targeting_cookie: cookies.adv,
    };

    setLoading(true);
    saveCookieConsent(requestJson)
      .then((res) => {
        localStorage.setItem(
          "cookie-consent",
          JSON.stringify({
            necessary: requestJson.strictly_necessary_cookie || false,
            perfomance: requestJson.performance_and_analytics_cookie || false,
            adv: requestJson.advertisement_and_targeting_cookie || false,
          })
        );
        localStorage.setItem("disclaimerResponded", "accepted");
        notify("Cookies successfully saved!");
        props.onClose();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const _acceptAll = () => {
    const requestJson = {
      strictly_necessary_cookie: true,
      performance_and_analytics_cookie: true,
      advertisement_and_targeting_cookie: true,
    };

    setCookies({
      necessary: true,
      perfomance: true,
      adv: true,
    });

    setLoading(true);
    saveCookieConsent(requestJson)
      .then((res) => {
        localStorage.setItem(
          "cookie-consent",
          JSON.stringify({
            necessary: requestJson.strictly_necessary_cookie || false,
            perfomance: requestJson.performance_and_analytics_cookie || false,
            adv: requestJson.advertisement_and_targeting_cookie || false,
          })
        );
        localStorage.setItem("disclaimerResponded", "accepted");
        notify("Cookies successfully saved!");
        props.onClose();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const _rejectAll = () => {
    const requestJson = {
      strictly_necessary_cookie: false,
      performance_and_analytics_cookie: false,
      advertisement_and_targeting_cookie: false,
    };

    setCookies({
      necessary: false,
      perfomance: false,
      adv: false,
    });

    setLoading(true);
    saveCookieConsent(requestJson)
      .then((res) => {
        localStorage.setItem(
          "cookie-consent",
          JSON.stringify({
            necessary: requestJson.strictly_necessary_cookie || false,
            perfomance: requestJson.performance_and_analytics_cookie || false,
            adv: requestJson.advertisement_and_targeting_cookie || false,
          })
        );
        localStorage.setItem("disclaimerResponded", "rejected");
        notify("Cookies successfully saved!");
        props.onClose();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Modal
      onClose={() => {
        props.onClose();
      }}
      onOpen={props.onClose}
      open={props.open}
      style={{ height: "90%" }}
      className={darkMode ? "cookie-modal-dark" : "cookie-modal-light"}
    >
      <Modal.Header
        className={
          darkMode ? "cookie-modal-head-dark" : "cookie-modal-head-light"
        }
        style={{ display: "flex", flex: 1 }}
      >
        <div style={{ display: "flex", flex: 0.5, alignItems: "center" }}>
          <span style={{ fontSize: "16px" }}>Cookie preferences</span>
        </div>
        <div
          className="cookie-close-action"
          style={{
            display: "flex",
            flex: 0.5,
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <MdClose
            style={{
              color: darkMode ? "lightgray" : "black",
              cursor: "pointer",
            }}
            onClick={props.onClose}
          />
        </div>
      </Modal.Header>
      <Modal.Content
        style={{
          backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
          borderRadius: "0px",
          padding: "5px",
        }}
        id="cookie-content"
      >
        <div className="cookie-body">
          <div className="cookie-body__title">
            Cookie usage <img src={megaphone} />
          </div>
          <div className="cookie-body__content">
            We use cookies to ensure the basic functionalities of the website
            and to enhance your online experience. You can choose for each
            category to opt-in/out whenever you want. For more details relative
            to cookies and other sensetive data, please read the full{" "}
            <NavLink to="/privacy" className="cookie-privacy">
              privacy policy
            </NavLink>
            .
          </div>
          <div className="cookie-body__actions">
            <CookieItem
              checked={cookies.necessary}
              onChange={_onCookieChange}
              name="necessary"
              id="necessary"
              label="Strictly necessary cookies"
              content="These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly."
              onClick={() =>
                activeContent === "necessary"
                  ? setActiveContent(null)
                  : setActiveContent("necessary")
              }
              isActive={activeContent === "necessary" ? true : false}
            />
            <CookieItem
              checked={cookies.perfomance}
              onChange={_onCookieChange}
              name="perfomance"
              id="perfomance"
              label="Perfomance and Analytics cookies"
              content="These cookies allow the website to remember the choices you have made in the past."
              onClick={() =>
                activeContent === "perfomance"
                  ? setActiveContent(null)
                  : setActiveContent("perfomance")
              }
              isActive={activeContent === "perfomance" ? true : false}
            />
            <CookieItem
              checked={cookies.adv}
              onChange={_onCookieChange}
              name="adv"
              id="adv"
              label="Advertisements and Targeting cookies"
              content="These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you."
              onClick={() =>
                activeContent === "adv"
                  ? setActiveContent(null)
                  : setActiveContent("adv")
              }
              isActive={activeContent === "adv" ? true : false}
            />
          </div>
        </div>
        <div className="cookie-footer">
          <div className="cookie-footer__left">
            <button
              onClick={_acceptAll}
              disabled={loading}
              className="accept-btn"
            >
              Accept All
            </button>
            <button
              onClick={_rejectAll}
              className="reject-btn"
              disabled={loading}
            >
              Reject All
            </button>
          </div>
          <div className="cookie-footer__right">
            <button
              onClick={_saveSettings}
              disabled={loading}
              className="save-btn"
            >
              {loading ? "Saving..." : "Save Settings"}
            </button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
