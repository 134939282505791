import React, { useEffect, useMemo, useState, useRef } from "react";

//packages
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CoverSlider from "../../components/CoverSlider/CoverSlider";

//components
// import SortBy from "../../components/SortBy/SortBy";
import Loader from "../../components/Loader/Loader";
import PostCard from "../../components/PostCard/PostCard";

//utils
import store from "../../redux/store";

//types
import {
  UPDATE_TAGS,
  CHANGE_TAGS_BAR_FIXED,
  SET_POST_CARD_DATA_MODAL,
} from "../../redux/types";
import { Dropdown } from "primereact/dropdown";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import DefaultCover from "../../assets/img/default-cover.png";
import { getBanners, getMostRecents } from "../../api/api";
import { isEmpty } from "../../validation/validation";
import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";
import { withRouter } from "react-router-dom";

const options = [
  { name: "Newest", code: "Newest", index: 1 },
  { name: "Popularity", code: "Popularity", index: 2 },
  { name: "Oldest", code: "Oldest", index: 3 },
];

function MostRecents(props) {
  const slides = [
    {
      image: props.settings.darkMode ? DefaultCover : DefaultCover,
      default: true,
    },
    {
      image: props.settings.darkMode ? DefaultCover : DefaultCover,
      default: true,
    },
    {
      image: props.settings.darkMode ? DefaultCover : DefaultCover,
      default: true,
    },
  ];
  const [postsArr, setPostsArr] = useState([]);
  const [sortBy, setSortBy] = useState(options[0]);
  const [coverBannerData, setCoverBannerData] = useState([...slides]);
  const loader = useRef(null);
  const [page, setPage] = useState(1);
  const [isFinished, setIsFinished] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  React.useEffect(() => {
    const controller = new AbortController();
    getBanners("?group=most-recent", controller.signal)
      .then((res) => {
        if (res.response.ok) {
          setCoverBannerData(res.json);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ piki_page: "most-recent" });
  }, [props.history]);

  React.useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("recents-page");
    document.getElementsByTagName("body")[0].removeAttribute("id");
    return () =>
      document.getElementsByTagName("body")[0].classList.remove("recents-page");
  }, []);

  //mostrecents data change
  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("activeSortType"))) {
      localStorage.setItem("activeSortType", JSON.stringify(options[0]));
    }

    // console.log("Sorted Post Lists : ", PostsList);
    // setPostsArr(props.mostRecents.postsArr);
    const CurrentSortType = JSON.parse(localStorage.getItem("activeSortType"));
  }, [props.mostRecents.postsArr]);

  const getMostRecentPosts = () => {
    setIsFetching(true);
    getMostRecents(
      `?per_page=10&paged=${page}&sort_by=${
        sortBy.code ? sortBy.code.toLowerCase() : ""
      }`
    )
      .then((res) => {
        if (res.response.ok) {
          const lengthNow = postsArr.length + res.json.length;
          if (lengthNow >= res.response.headers.get("x-wp-total")) {
            setIsFinished(true);
          }
          res = res.json;
          setPostsArr([...postsArr, ...res]);
          setIsFetching(false);
          setPage(page + 1);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsFinished(true);
      });
  };

  //For observer
  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      !isFetching && !isFinished && getMostRecentPosts();
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };
    // Create observer
    const observer = new IntersectionObserver(handleObserver, options);
    // observe the loader
    if (loader && loader.current) {
      observer.observe(loader.current);
    }
    // clean up on willUnMount
    return () => observer.unobserve(loader.current);
  }, [loader, handleObserver]);

  //update the tags on load
  useEffect(() => {
    props.mostRecents.postsArr.forEach((post) => {
      store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
    });
  }, [props.mostRecents]);

  const _sortByChange = async (e) => {
    // console.log("sort on change : ", e.target.value);
    await localStorage.setItem(
      "activeSortType",
      JSON.stringify({ name: e, code: e })
    );
    setSortBy({ name: e, code: e });
  };

  const _openPostModal = (id) => {
    store.dispatch({
      type: SET_POST_CARD_DATA_MODAL,
      payload: { postCardModal: true, activePostCardModal: id },
    });
  };

  const PostsList = useMemo(() => {
    return postsArr
      .filter((post) => {
        return props.activeTag
          ? post.tags.find((myTag) => myTag.term_id === props.activeTag.term_id)
          : true;
      })
      .sort((a, b) => {
        if (sortBy.code === "newest") {
          return new Date(b.post_date) - new Date(a.post_date);
        } else if (sortBy.code === "popularity") {
          return +b.up_vote_count - +a.up_vote_count;
        } else {
          return new Date(a.post_date) - new Date(b.post_date);
        }
      })
      .map((post, index) => {
        return (
          <PostCard
            postsArr={postsArr}
            onOpen={() => _openPostModal(post.ID)}
            key={index}
            upvotedMe={(store.getState().world.upvotedPosts || []).find(
              (postt) => postt.ID === post.ID
            )}
            postData={post}
            postId={index}
            replaceDate={`${new Date(post.post_date).toLocaleDateString(
              "en-us",
              { year: "numeric", month: "short", day: "numeric" }
            )}${post.attribute ? " | " + post.attribute : ""}`}
          />
        );
      });
  }, [postsArr, props.activeTag, sortBy]);

  const _setActiveSlide = (slide) => {
    if (localStorage.getItem("userToken")) {
      store.dispatch({ type: "SET_ACTIVE_SLIDE_MOST_RECENT", payload: slide });
    } else {
      store.dispatch({
        type: "SET_UNAUTH_ACTIVE_SLIDE_MOST_RECENT",
        payload: slide,
      });
    }
  };

  // const slides = [
  // 	{
  // 		image: props.settings.darkMode ? "https://app.pikilinks.com/wp-content/uploads/2021/06/1-LISTS-DARK-scaled.jpg" : "https://app.pikilinks.com/wp-content/uploads/2021/06/1-LISTS-LIGHT-scaled.jpg",
  // 		text: "Discover something new"
  // 	},
  // 	{
  // 		image: props.settings.darkMode ? "https://app.pikilinks.com/wp-content/uploads/2021/06/2-LISTS-DARK-scaled.jpg" : "https://app.pikilinks.com/wp-content/uploads/2021/06/2-LISTS-LIGHT-scaled.jpg",
  // 		text: "Discover something new"
  // 	},
  // 	{
  // 		image: props.settings.darkMode ? "https://app.pikilinks.com/wp-content/uploads/2021/06/3-LISTS-DARK-scaled.jpg" : "https://app.pikilinks.com/wp-content/uploads/2021/06/3-LISTS-LIGHT-scaled.jpg",
  // 		text: "Discover something new"
  // 	}
  // ];

  let isMobile = window.innerWidth <= 992;

  return (
    <div className="p-grid p-fluid dashboard page-most-recents">
      <MetaDecorator metaDataPerRoute={metaDecoratorData.recent} />
      {props.settings.bannersMode ? (
        <div
          className={`${
            isMobile ? "CoverBannerMobile" : "CoverBanner"
          } banner-recent`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: isEmpty(coverBannerData)
              ? "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)"
              : "transparent",
            paddingTop: isEmpty(coverBannerData) ? "20px" : "",
            animation: "gradient 15s ease infinite",
            backgroundSize: "400% 400%",
            minHeight: isEmpty(coverBannerData)
              ? isMobile
                ? "20vh"
                : "30vh"
              : "",
            maxHeight: isEmpty(coverBannerData) ? (isMobile ? "20vh" : "") : "",
          }}
        >
          <CoverSlider
            isMobile={isMobile}
            darkMode={props.settings.darkMode}
            activeSlide={
              localStorage.getItem("userToken")
                ? props.settings.activeSlideMostRecent
                : props.settings.unauthActiveSlideMostRecent
            }
            setActiveSlide={_setActiveSlide}
            cattitle={isEmpty(coverBannerData) ? "Recent Links" : ""}
            slides={coverBannerData}
          />
        </div>
      ) : null}
      {!isFetching && (
        <div className="p-col-12 p-lg-12">
          {!props.settings.bannersMode ? (
            <div
              className="p-col-12 page-header"
              style={{
                marginTop:
                  coverBannerData.length === 1
                    ? props.settings.bannersMode
                      ? "-20px"
                      : "0px"
                    : "",
              }}
            >
              <i class="fab fa-gripfire"></i>
              <b style={{ display: "flex" }}>Recent Links</b>
            </div>
          ) : !isEmpty(coverBannerData) ? (
            <div
              className="p-col-12"
              style={{
                marginTop:
                  coverBannerData.length === 1
                    ? props.settings.bannersMode
                      ? "-20px"
                      : "0px"
                    : "",
              }}
            >
              <b style={{ display: "flex", marginBottom: "-20px" }}>
                Recent Links{" "}
              </b>
            </div>
          ) : (
            <div></div>
          )}
          <div
            className="p-col-12 post-filter"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: props.settings.bannersMode
                ? "space-between"
                : "flex-end",
            }}
          >
            {props.settings.bannersMode && (
              <div
                className="cat-separator"
                style={{ opacity: isEmpty(coverBannerData) ? 1 : 0 }}
              ></div>
            )}
            <span>
              <CustomDropdown
                handleOnClick={_sortByChange}
                sortByValue={sortBy.code ? sortBy.code : "Sort By"}
                dropdownName="Sort By"
                dropdownOptions={options}
              />
              {/* <Dropdown optionLabel="name" placeholder="Sort By" onChange={_sortByChange} options={options} value={JSON.parse(localStorage.getItem("activeSortType"))} /> */}
            </span>
          </div>
        </div>
      )}
      {PostsList}
      <div
        ref={loader}
        style={{ display: "block" }}
        className="loading-more-wrapper"
      >
        {isFetching && !isFinished && <Loader loadingMore={true} />}
        {isFinished && postsArr.length < 1 && (
          <div className="text-centered">
            <b>No links found</b>
          </div>
        )}
      </div>
      {/* {props.mostRecents.loading ? <Loader /> : PostsList} */}
    </div>
  );
}

MostRecents.propTypes = {
  mostRecents: PropTypes.object.isRequired,
  activeTag: PropTypes.object,
};

const mapStateToProps = (state) => ({
  mostRecents: state.mostRecents,
  activeTag: state.world.activeTag,
  settings: state.settings,
});

export default connect(mapStateToProps)(withRouter(MostRecents));
