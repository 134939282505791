import React, { useEffect, useRef, useMemo, useState } from "react";

//from packages
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import CoverSlider from "../../components/CoverSlider/CoverSlider";
//components
import ListItem from "./ListItem";
import Loader from "../../components/Loader/Loader";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";

//apis
import { getLists, getBanners, getGroups } from "../../api/api";

//utils
import store from "../../redux/store";
import { listSortOptions } from "../../utils/commons";

//types
import { UPDATE_LISTS } from "../../redux/types";

import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";
import BannerLists from "../../assets/img/BannerLists.jpg";
import DefaultCover from "../../assets/img/default-cover.png";
import { isEmpty } from "../../validation/validation";
import { isArray } from "lodash";

const sortByOptions = [
  { name: "Newest", code: "Newest", index: 1 },
  { name: "Random", code: "Random", index: 2 },
  { name: "Popularity", code: "Popularity", index: 3 },
  { name: "Oldest", code: "Oldest", index: 4 },
];

let groupOptions = [{ name: "All Groups", code: "all-groups", index: 1 }];

function Lists(props) {
  const { listsArr, isFetching, isFinished, nextPage, preloaded } = props.lists;
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [sortBy, setSortBy] = useState(
    JSON.parse(localStorage.getItem("listsSortBy")) || sortByOptions[0]
  );
  const slides = [
    {
      image: darkMode ? DefaultCover : DefaultCover,
      default: true,
    },
    {
      image: darkMode ? DefaultCover : DefaultCover,
      default: true,
    },
    {
      image: darkMode ? DefaultCover : DefaultCover,
      default: true,
    },
  ];
  const [coverBannerData, setCoverBannerData] = useState([...slides]);
  const loader = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [activeGroup, setActiveGroup] = useState(
    JSON.parse(localStorage.getItem("listsGroupBy")) || groupOptions[0]
  );

  React.useEffect(() => {
    const controller = new AbortController();

    getBanners("?group=list", controller.signal)
      .then((resp) => {
        if (resp.response.ok) {
          setCoverBannerData([...resp.json]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => controller.abort();
  }, []);

  useEffect(() => {
    store.dispatch({
      type: UPDATE_LISTS,
      payload: { isFetching: true, nextPage: 2 },
    });
    let groupQuery = "";
    if (activeGroup.code !== "all-groups") {
      groupQuery = `&series_group=${activeGroup.code}`;
    }
    getLists(
      `paged=1&per_page=10&sort_by=${
        sortBy && sortBy.code.toLowerCase()
      }&query=${searchText}${groupQuery}`
    )
      .then((res) => {
        const lengthNow = res.json.series.length;
        if (lengthNow < 10) {
          console.log(
            "finished::",
            lengthNow,
            res.response.headers.get("x-wp-total")
          );
          props.updateLists({ isFinished: true });
        }
        res = res.json;
        props.updateLists({
          isFetching: false,
          nextPage: 2,
          listsArr: [...res.series],
        });
      })
      .catch((err) => {
        // handle errors
        props.updateLists({ isFinished: true });
      });
  }, []);

  const GetLists = () => {
    !isFinished &&
      store.dispatch({
        type: UPDATE_LISTS,
        payload: { ...props.lists, isFetching: true },
      });
    let groupQuery = "";
    if (activeGroup.code !== "all-groups") {
      groupQuery = `&series_group=${activeGroup.code}`;
    }
    getLists(
      `paged=${nextPage}&per_page=10&sort_by=${
        sortBy && sortBy.code.toLowerCase()
      }&query=${searchText}${groupQuery}`
    )
      .then((res) => {
        const lengthNow = listsArr.length + res.json.series.length;
        if (lengthNow >= res.response.headers.get("x-wp-total")) {
          props.updateLists({ isFinished: true });
        }
        res = res.json;
        props.updateLists({
          isFetching: false,
          nextPage: nextPage + 1,
          listsArr: [...listsArr, ...res.series],
        });
      })
      .catch((err) => {
        props.updateLists({ isFinished: true });
      });
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ piki_page: "lists" });
  }, [props.history]);

  //did mount
  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("lists-page");
    if (localStorage.getItem("listsSortBy")) {
      setSortBy(JSON.parse(localStorage.getItem("listsSortBy")));
    }

    if (localStorage.getItem("listsGroupBy")) {
      setActiveGroup(JSON.parse(localStorage.getItem("listsGroupBy")));
    }

    return () => {
      document.getElementsByTagName("body")[0].classList.remove("lists-page");
    };
  }, []);

  //For observer
  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      if (!isFetching) {
      }
      !isFetching && !isFinished && preloaded && GetLists();
    }
  };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };
    // Create observer
    const observer = new IntersectionObserver(handleObserver, options);
    // observe the loader
    if (loader && loader.current) {
      observer.observe(loader.current);
    }
    // clean up on willUnMount
    return () => observer.unobserve(loader.current);
  }, [props.lists]);

  const _sortByChange = (e) => {
    const index = { Newest: 1, Random: 2, Popularity: 3, Oldest: 4 };
    let sortByValue = { name: e, code: e, index: index[e] };
    setSortBy(sortByValue);
    store.dispatch({
      type: UPDATE_LISTS,
      payload: {
        isFetching: false,
        isFinished: false,
        nextPage: 1,
        listsArr: [],
        sortBy: sortByValue,
        query: searchText,
        groupBy: activeGroup,
      },
    });
    localStorage.setItem("listsSortBy", JSON.stringify(sortByValue));
  };

  useEffect(() => {
    const controller = new AbortController();
    getGroups("", controller.signal)
      .then((res) => {
        if (isArray(res.json)) {
          let tempGroupsList = [];

          res.json.forEach((element, ind) => {
            tempGroupsList.push({
              name: element.name,
              code: element.term_id.toString() || element.name,
              index: ind + 2,
            });
          });

          groupOptions = [groupOptions[0], ...tempGroupsList];
        }
      })
      .catch((err) => {
        // handle errors
      });
  }, []);

  const ListsList = useMemo(() => {
    const lists = (listsArr || []).map((post, index) => {
      return <ListItem key={index} listData={post} />;
    });

    return lists;
  }, [listsArr, props.activeTag, props.lists.listsArr]);

  const _search = (e) => {
    // search
    let searchText = e.target.value;
    setSearchText(searchText);

    store.dispatch({
      type: UPDATE_LISTS,
      payload: {
        isFetching: false,
        isFinished: false,
        nextPage: 1,
        listsArr: [],
        sortBy: sortBy && sortBy.code ? sortBy.code : "",
        query: searchText,
        groupBy: activeGroup,
      },
    });
  };

  const _setActiveSlide = (slide) => {
    if (localStorage.getItem("userToken")) {
      store.dispatch({ type: "SET_ACTIVE_SLIDE_LISTS", payload: slide });
    } else {
      store.dispatch({ type: "SET_UNAUTH_ACTIVE_SLIDE_LISTS", payload: slide });
    }
  };
  let isMobile = window.innerWidth <= 992;

  const _groupChange = (e) => {
    const selectedGroup = groupOptions.filter((d) => d.name === e);
    if (selectedGroup && selectedGroup[0]) {
      setActiveGroup(selectedGroup[0]);
      const acGroup =
        selectedGroup[0] && selectedGroup[0].code !== "all-groups"
          ? selectedGroup[0]
          : groupOptions[0];
      store.dispatch({
        type: UPDATE_LISTS,
        payload: {
          isFetching: false,
          isFinished: false,
          nextPage: 1,
          listsArr: [],
          sortBy: sortBy,
          query: searchText,
          groupBy: acGroup,
        },
      });
      localStorage.setItem("listsGroupBy", JSON.stringify(acGroup));
    }
  };

  return (
    <div className={`p-grid p-fluid dashboard page-list`}>
      <MetaDecorator metaDataPerRoute={metaDecoratorData.lists} />
      {props.settings.bannersMode ? (
        <div
          className={`${
            isMobile ? "CoverBannerMobile" : "CoverBanner"
          } ListsBanner`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: isEmpty(coverBannerData)
              ? "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)"
              : "transparent",
            paddingTop: isEmpty(coverBannerData) ? "20px" : "",
            animation: "gradient 15s ease infinite",
            backgroundSize: "400% 400%",
            minHeight: isEmpty(coverBannerData)
              ? isMobile
                ? "20vh"
                : "30vh"
              : "",
            maxHeight: isEmpty(coverBannerData) ? (isMobile ? "20vh" : "") : "",
          }}
        >
          <CoverSlider
            isMobile={isMobile}
            darkMode={darkMode}
            activeSlide={
              localStorage.getItem("userToken")
                ? props.settings.activeSlideLists
                : props.settings.unauthActiveSlideLists
            }
            setActiveSlide={_setActiveSlide}
            cattitle={isEmpty(coverBannerData) ? "Lists" : ""}
            slides={coverBannerData}
          />
        </div>
      ) : null}
      <div className="p-col-12 p-lg-12">
        {(!props.settings.bannersMode || !isEmpty(coverBannerData)) && (
          <div
            className={`p-col-12 ${
              props.settings.darkMode ? "page-header" : "page-header-light"
            }`}
            style={{
              marginTop:
                coverBannerData.length === 1
                  ? props.settings.bannersMode
                    ? "-20px"
                    : "0px"
                  : "",
            }}
          >
            <i class="glyph-icon flaticon-list active"></i>
            <b>Lists</b>
          </div>
        )}
      </div>
      <div className="lists-header-action-container">
        <div
          className={`lists-header-action-container__search${
            props.settings.darkMode ? "" : "-light"
          } ${
            !isEmpty(searchText)
              ? props.settings.darkMode
                ? "search-active"
                : "search-active-light"
              : ""
          }`}
        >
          <label for="list-search" className={`lists-search-link`}>
            <i class="glyph-icon flaticon-loupe"></i>
          </label>
          <input
            name="list-search"
            id="list-search"
            className="lists-search-input"
            placeholder="Search Lists"
            onChange={_search}
          />
        </div>
        <div className="lists-header-action-container__dropdowns">
          <CustomDropdown
            handleOnClick={_groupChange}
            sortByValue={activeGroup.name}
            dropdownName="All Groups"
            dropdownOptions={groupOptions}
          />
          <CustomDropdown
            handleOnClick={_sortByChange}
            sortByValue={sortBy.name}
            dropdownName="Sort By"
            dropdownOptions={sortByOptions}
          />
        </div>
      </div>

      {ListsList}
      <div
        ref={loader}
        style={{ display: "block" }}
        className="loading-more-wrapper"
      >
        {isFetching && !isFinished && <Loader loadingMore={true} />}
        {isFinished && listsArr.length < 1 && (
          <div className="text-centered">
            <b>No Links found</b>
          </div>
        )}
      </div>
    </div>
  );
}

Lists.propTypes = {
  lists: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  lists: state.lists,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    updateLists: (payload) => dispatch({ type: UPDATE_LISTS, payload }),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Lists));
