import React, { useEffect, useMemo, useState, useRef } from "react";

//packages
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CoverSlider from "../../components/CoverSlider/CoverSlider";

//components
import Loader from "../../components/Loader/Loader";
import PostCard from "../../components/PostCard/PostCard";
import { isEmpty } from "../../validation/validation";
//utils
import store from "../../redux/store";

//types
import { UPDATE_TAGS, SET_POST_CARD_DATA_MODAL } from "../../redux/types";
import { Dropdown } from "primereact/dropdown";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import { getBanners, getMostPopulars } from "../../api/api";
import DefaultCover from "../../assets/img/default-cover.png";
import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";

const options = [
  { name: "Popularity", code: "Popularity", index: 1 },
  { name: "Newest", code: "Newest", index: 2 },
  { name: "Oldest", code: "Oldest", index: 3 },
];

function MostPopulars(props) {
  const slides = [
    {
      image: props.settings.darkMode ? DefaultCover : DefaultCover,
      default: true,
    },
    {
      image: props.settings.darkMode ? DefaultCover : DefaultCover,
      default: true,
    },
    {
      image: props.settings.darkMode ? DefaultCover : DefaultCover,
      default: true,
    },
  ];

  const [postsArr, setPostsArr] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState(options[0]);
  const [coverBannerData, setCoverBannerData] = useState([...slides]);
  const loader = useRef(null);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  // const [mostPopularPosts,setMostPopularPosts] = useState([]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ piki_page: "most-popular" });
  }, [props.history]);

  //did mount
  React.useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .classList.add("most-populars-page");
    document.getElementsByTagName("body")[0].removeAttribute("id");
    return () =>
      document
        .getElementsByTagName("body")[0]
        .classList.remove("most-populars-page");
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    getBanners("?group=most-popular", controller.signal)
      .then((res) => {
        if (res.response.ok) {
          setCoverBannerData([...res.json]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //mostPopulars data change
  const getPopularPosts = () => {
    setIsFetching(true);
    getMostPopulars(
      `?per_page=10&paged=${page}&sort_by=${
        sortBy.code ? sortBy.code.toLowerCase() : ""
      }`
    )
      .then((res) => {
        if (res.response.ok) {
          const lengthNow = postsArr.length + res.json.posts.length;
          if (lengthNow >= res.response.headers.get("x-wp-total")) {
            setIsFinished(true);
          }
          res = res.json;
          setPostsArr([...postsArr, ...res.posts]);
          setIsFetching(false);
          setPage(page + 1);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsFinished(true);
      });
  };

  //For observer
  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      !isFetching && !isFinished && getPopularPosts();
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };
    // Create observer
    const observer = new IntersectionObserver(handleObserver, options);
    // observe the loader
    if (loader && loader.current) {
      observer.observe(loader.current);
    }
    // clean up on willUnMount
    return () => observer.unobserve(loader.current);
  }, [loader, handleObserver]);

  //update the tags on load
  useEffect(() => {
    props.mostPopulars.postsArr.forEach((post) => {
      store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
    });
  }, [props.mostPopulars]);

  const _sortByChange = (e) => {
    setSortBy({ name: e, code: e });
  };

  const _openPostModal = (id) => {
    store.dispatch({
      type: SET_POST_CARD_DATA_MODAL,
      payload: { postCardModal: true, activePostCardModal: id },
    });
  };

  const PostsList = useMemo(() => {
    if (postsArr) {
      return postsArr
        .filter((post) => {
          return props.activeTag
            ? post.tags.find(
                (myTag) => myTag.term_id === props.activeTag.term_id
              )
            : true;
        })
        .sort((a, b) => {
          if (sortBy.code.toLowerCase() === "newest") {
            return new Date(b.post_date) - new Date(a.post_date);
          } else if (sortBy.code.toLowerCase() === "popularity") {
            return +b.up_vote_count - +a.up_vote_count;
          } else {
            return new Date(a.post_date) - new Date(b.post_date);
          }
        })
        .map((post, index) => {
          return (
            <PostCard
              postsArr={postsArr}
              onOpen={() => _openPostModal(post.ID)}
              key={index}
              upvotedMe={(store.getState().world.upvotedPosts || []).find(
                (postt) => postt.ID === post.ID
              )}
              postData={post}
              postId={index}
            />
          );
        });
    }
  }, [postsArr, props.activeTag, sortBy]);

  const _setActiveSlide = (slide) => {
    if (localStorage.getItem("userToken")) {
      store.dispatch({ type: "SET_ACTIVE_SLIDE_MOST_POPULAR", payload: slide });
    } else {
      store.dispatch({
        type: "SET_UNAUTH_ACTIVE_SLIDE_MOST_POPULAR",
        payload: slide,
      });
    }
  };

  let isMobile = window.innerWidth <= 992;

  return (
    <div className="p-grid p-fluid dashboard page-most-recents">
      <MetaDecorator metaDataPerRoute={metaDecoratorData.popular} />
      {props.settings.bannersMode ? (
        <div
          className={`${
            isMobile ? "CoverBannerMobile" : "CoverBanner"
          } banner-popular`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: isEmpty(coverBannerData)
              ? "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)"
              : "transparent",
            paddingTop: isEmpty(coverBannerData) ? "20px" : "",
            animation: "gradient 15s ease infinite",
            backgroundSize: "400% 400%",
            minHeight: isEmpty(coverBannerData)
              ? isMobile
                ? "20vh"
                : "30vh"
              : "",
            maxHeight: isEmpty(coverBannerData) ? (isMobile ? "20vh" : "") : "",
          }}
        >
          <CoverSlider
            isMobile={isMobile}
            darkMode={props.settings.darkMode}
            activeSlide={
              localStorage.getItem("userToken")
                ? props.settings.activeSlideMostPopular
                : props.settings.unauthActiveSlideMostPopular
            }
            setActiveSlide={_setActiveSlide}
            cattitle={isEmpty(coverBannerData) ? "Populars Links" : ""}
            slides={coverBannerData}
          />
        </div>
      ) : null}
      {!isFetching && (
        <div className="p-col-12 p-lg-12">
          {!props.settings.bannersMode ? (
            <div
              className={`p-col-12 ${
                props.settings.darkMode ? "page-header" : "page-header-light"
              }`}
              style={{
                marginTop:
                  coverBannerData.length === 1
                    ? props.settings.bannersMode
                      ? "-20px"
                      : "0px"
                    : "",
              }}
            >
              <i class="fab fa-gripfire"></i>
              <b style={{ display: "flex" }}>Popular Links</b>
            </div>
          ) : !isEmpty(coverBannerData) ? (
            <div
              className={`p-col-12 ${
                props.settings.darkMode ? "page-header" : "page-header-light"
              }`}
              style={{
                marginTop:
                  coverBannerData.length === 1
                    ? props.settings.bannersMode
                      ? "-20px"
                      : "0px"
                    : "",
              }}
            >
              <i class="fab fa-gripfire"></i>
              <b style={{ display: "flex" }}>Popular Links</b>
            </div>
          ) : (
            <div></div>
          )}
          <div
            className="p-col-12 post-filter"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: props.settings.bannersMode
                ? "space-between"
                : "flex-end",
            }}
          >
            {props.settings.bannersMode && (
              <div
                className="cat-separator"
                style={{ opacity: isEmpty(coverBannerData) ? 1 : 0 }}
              ></div>
            )}
            <span>
              <CustomDropdown
                handleOnClick={_sortByChange}
                sortByValue={sortBy.code ? sortBy.code : "Sort By"}
                dropdownName="Sort By"
                dropdownOptions={options}
              />
              {/* <Dropdown optionLabel="name" placeholder="Sort By" onChange={_sortByChange} options={options} value={sortBy} /> */}
            </span>
          </div>
        </div>
      )}
      {PostsList}
      {/* {props.mostPopulars.loading ? <Loader /> : PostsList} */}
      <div
        ref={loader}
        style={{ display: "block" }}
        className="loading-more-wrapper"
      >
        {isFetching && !isFinished && <Loader loadingMore={true} />}
        {isFinished && postsArr.length < 1 && (
          <div className="text-centered">
            <b>No links found</b>
          </div>
        )}
      </div>
    </div>
  );
}

MostPopulars.propTypes = {
  mostPopulars: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  activeTag: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  mostPopulars: state.mostPopulars,
  tags: state.tags,
  activeTag: state.world.activeTag,
  settings: state.settings,
});

export default withRouter(connect(mapStateToProps)(MostPopulars));
