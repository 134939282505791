import React, { useState, useEffect, useRef } from "react";

//from modules
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import {
  // NavLink,
  withRouter,
  Link,
} from "react-router-dom";
import {
  Popup,
  Icon,
  Dropdown,
  Image,
  DropdownDivider,
  DropdownItem,
} from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { BsThreeDots } from "react-icons/bs";
import { BiWindowOpen } from "react-icons/bi";
import { MdTune, MdDeleteForever } from "react-icons/md";
import { GoBrowser } from "react-icons/go";
//hooks
import { usePrevious } from "../../hooks/usePrevious";

//utils
import store from "../../redux/store";
import icon192 from "../../assets/img/icon192.png";
import AuthModal from "./AuthModal";
import ForgotPasswordModal from "./ForgotPasswordModal";
import ResetCodeSentModal from "./ResetCodeSentModal";
import { GrMultimedia } from "react-icons/gr";
import { DiHtml5Multimedia } from "react-icons/di";

import GLOBALS from "../../constants";
import Axios from "axios";
import _ from "lodash";
import { isEmpty } from "../../validation/validation";

//types
import {
  // SET_SEARCH_RESULTS_PAGE,
  RESET_TAGS,
  SET_DARK_MODE,
  SET_LIST_MODE,
  SET_MENU_ICON_MODE,
  SET_SEARCH_VALUE,
  SEARCH_INPUT_SHOWING,
  SET_TAGS_MODE,
  REFRESH_MENU_ORDER,
  PROMPT_ADD_TO_HOME_SCREEN,
  SET_BANNERS_MODE,
  SET_CAT_LIST,
  SET_UPVOTES,
  SET_SUBSCRIBE_BUTTON_STATUS,
  SET_AUTH_USER,
  SET_LINK_MODE,
  SET_WINDOW_MODE,
  SET_FILTER_BAR,
  SET_FEEDBACK_MODAL,
  SET_UNCHECKED_LINKS,
  UNINSTALL_PWA_MODAL,
  SET_AUTH_FAVOURITE_POSTS,
} from "../../redux/types";
import { detect } from "detect-browser";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { toast } from "react-toastify";
import Del from "../../components/Toast/DeleteToast/Del";
import {
  deleteAcc,
  getLinksData,
  getPostsByIds,
  getSocialIcons,
} from "../../api/api";
import { icons } from "react-icons/lib/cjs";
import { FaChalkboardTeacher } from "react-icons/fa";

const topDropdown = [
  { name: "link1", label: "Link1" },
  { name: "link2", label: "Link2" },
  { name: "link3", label: "Link3" },
];

const socialMedia = [
  {
    fieldName: "reddit",
    icon: ["fab fa-reddit-alien", "fab fa-reddit-alien"],
    url: "https://www.reddit.com",
  },
  {
    fieldName: "twitter",
    icon: ["fab fa-twitter", "fab fa-twitter"],
    url: "https://www.twitter.com",
  },
  {
    fieldName: "plane",
    icon: ["fas fa-paper-plane", "fas fa-paper-plane"],
    url: "https://www.instagram.com",
  },
  {
    fieldName: "discord",
    icon: ["fab fa-discord", "fab fa-discord"],
    url: "https://www.discord.com",
  },
];

const dropdownButtons = [
  {
    fieldName: "listMode",
    icon: ["glyph-icon flaticon-grid", "glyph-icon flaticon-list-text"],
    text: "Orientation : ",
    value: "orientation",
    labels: ["ROWS", "GRID"],
  },
  {
    fieldName: "darkMode",
    icon: ["glyph-icon flaticon-sun", "glyph-icon flaticon-moon"],
    text: "Appeareance : ",
    value: "appeareance",
    labels: ["DARK", "LIGHT"],
  },
  {
    fieldName: "windowMode",
    icon: ["glyph-icon flaticon-square", "glyph-icon flaticon-square-1"],
    text: "Open Links :  ",
    value: "open-links",
    labels: ["WINDOW", "TABS"],
  },
  {
    fieldName: "linkIcons",
    icon: ["glyph-icon flaticon-square", "glyph-icon flaticon-square-1"],
    text: "Link icons : ",
    value: "link-icons",
    labels: ["SHOW", "HIDE"],
  },
  {
    fieldName: "filterBar",
    icon: ["glyph-icon flaticon-square", "glyph-icon flaticon-square-1"],
    text: "Filter Bar : ",
    value: "filter-bar",
    labels: ["SHOW", "HIDE"],
  },
  {
    fieldName: "tagsMode",
    icon: ["glyph-icon flaticon-tag", "glyph-icon flaticon-tag-1"],
    text: "Tags Bar : ",
    value: "tags",
    labels: ["SHOW", "HIDE"],
  },
  {
    fieldName: "bannersMode",
    icon: ["glyph-icon flaticon-picture", "glyph-icon flaticon-picture-1"],
    text: "Banners : ",
    value: "banners",
    labels: ["SHOW", "HIDE"],
  },
  {
    fieldName: "refreshMenuOrder",
    icon: ["glyph-icon flaticon-reload", "glyph-icon flaticon-reload"],
    text: "Nav Order : ",
    value: "navOrder",
    labels: ["REFRESH", "REFRESH"],
  },
];

let dropdownLinks = [
  {
    fieldName: "isIconLibraryActive",
    icon: ["glyph-icon flaticon-bank ", "glyph-icon flaticon-bank-1"],
    text: "Icon Library",
    value: "iconLibrary",
    link: "/icon-library",
  },
  {
    fieldName: "isAboutActive",
    icon: ["glyph-icon flaticon-info ", "glyph-icon flaticon-info-1"],
    text: "Info",
    value: "info",
    link: "/info",
  },
  {
    fieldName: "howTo",
    icon: ["glyph-icon flaticon-info ", "glyph-icon flaticon-info-1"],
    text: "How To",
    value: "howTo",
    link: "/how-to",
  },
  {
    fieldName: "isFeedbackActive",
    icon: ["glyph-icon flaticon-comment", "glyph-icon flaticon-comment-1"],
    text: "Send Feeback",
    value: "sendFeedback",
    link: "/send-feedback",
  },
  // {
  // 	fieldName: "isTermsActive",
  // 	icon: ["glyph-icon flaticon-file", "glyph-icon flaticon-file-1"],
  // 	text: "Terms & Conditions",
  // 	value: "termsAndConditions",
  // 	link: "/terms-and-conditions"
  // },
  {
    fieldName: "isPlartformActive",
    icon: ["glyph-icon flaticon-shield", "glyph-icon flaticon-shield-1"],
    text: "Platforms",
    value: "platforms",
    link: "/platforms",
  },
  {
    fieldName: "uninstallPwa",
    icon: ["fas fa-arrow-alt-circle-down"],
    text: "Uninstall PikiLinks",
    value: "uninstallPikiLinks",
    // link: "/send-feedback",
  },
  {
    fieldName: "isPikiLinksActive",
    icon: ["fas fa-arrow-alt-circle-down"],
    text: "Install PikiLinks",
    value: "installPikiLinks",
    // link: "/send-feedback",
  },
];

const AppTopbar = (props) => {
  const [searchData, setSearchData] = useState({
    searchValue: "",
    searchFocused: false,
  });

  const [settings, setSettings] = useState({
    listMode: false,
    darkMode: false,
    bannersMode: true,
    linkIcons: true,
    filterBar: false,
    tagsMode: true,
    windowMode: true,
  });

  const [showModal, setShowmodal] = useState(false);
  const [whichModal, setWhichmodal] = useState();
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [resetCodeSentModal, setResetCodeSentModal] = useState(false);
  const [emailToRenewPassword, setEmailToRenewPassword] = useState(false);
  const [previousModal, setPreviousModal] = useState("");
  // ShowBanner
  const [isGearActive, setIsGearActive] = useState(false);
  const [searchInputShowing, setSearchInputShowing] = React.useState(false);
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const [socialIcons, setSocialIcons] = useState([]);

  // const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const proposalEvent = useSelector((state) => state.pwaInstall.proposalEvent);
  const uninstallPwaStatus = useSelector(
    (state) => state.pwaInstall.pwaWindowOn
  );
  const [accDeleteStatus, setAccDeleteStatus] = React.useState("");
  const [accDelLoader, setAccDelLoader] = React.useState(false);
  const iconMode = useSelector((state) => state.settings.menuIconMode);
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [browserName, setBrowserName] = useState("");
  const SearchInputRef = useRef();
  const [linksData, setLinksData] = React.useState([]);
  const [defaultInfo, setDefaultInfo] = React.useState({});

  React.useEffect(() => {
    (async () => {
      if (localStorage.getItem("userToken")) {
        store.dispatch({ type: SET_CAT_LIST, payload: [] });
        return Axios({
          url: `${GLOBALS.SERVER_URL}wp-json/ng/v1/favorite-list`,
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
        })
          .then(async (finRes) => {
            if (finRes.status === 200) {
              let ids = [];
              finRes.data.posts.map((data) => {
                if (!isEmpty(data.categories)) {
                  ids.push(data.ID);
                }
              });
              const uniqueIds = _.uniq(ids);

              // get fav posts full details
              await getPostsByIds(`ids=${uniqueIds.join(",")}`)
                .then((finRess) => {
                  // FavPosts = finRes.json.posts;
                  store.dispatch({
                    type: SET_AUTH_FAVOURITE_POSTS,
                    payload: finRess.json.posts,
                  });
                  // setPostsArr(finRess.json.posts);
                  // setLoading(false);
                })
                .catch((err) => {
                  // console.log(err);
                  // setLoading(false);
                });
            } else {
              // setLoading(false);
            }
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    })();
  }, [localStorage.getItem("userToken")]);

  useEffect(() => {
    const controller = new AbortController();
    getLinksData("", controller.signal)
      .then((res) => {
        if (res.response.ok) {
          setLinksData(res.json);
        }
      })
      .catch((err) => {});
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = showModal ? "hidden" : "auto";
  }, [showModal]);

  useEffect(() => {
    setBrowserName(detect());
    if (isTabletOrMobileDevice) {
      dropdownLinks[dropdownLinks.length - 1] = {
        fieldName: "isPWALinkActive",
        icon: ["glyph-icon flaticon-download", "glyph-icon flaticon-download"],
        text: "Add to Home Screen",
        value: "addtoHomeScreen",
        // link: "/send-feedback",
      };
    } else {
      dropdownLinks[dropdownLinks.length - 1] = {
        fieldName: "isPWALinkActive",
        icon: ["glyph-icon flaticon-download", "glyph-icon flaticon-download"],
        text: "Install PikiLinks",
        value: "installPikiLinks",
        // link: "/send-feedback",
      };
    }

    return () => {
      store.dispatch({ type: SET_LIST_MODE, payload: false });
    };
  }, [isTabletOrMobileDevice]);

  React.useEffect(() => {
    if (props.world.authModalChange) {
      setWhichmodal("register");
      setPreviousModal("login");
      setShowmodal(true);
    }
  }, [props.world.authModalChange]);

  React.useEffect(() => {
    if (localStorage.getItem("isSubVisible")) {
      store.dispatch({
        type: SET_SUBSCRIBE_BUTTON_STATUS,
        payload: localStorage.getItem("isSubVisible"),
      });
    }
  }, []);

  React.useEffect(() => {
    if (
      localStorage.getItem("userToken") &&
      localStorage.getItem("userEmail")
    ) {
      store.dispatch({
        type: SET_AUTH_USER,
        payload: {
          email: localStorage.getItem("userEmail"),
          token: localStorage.getItem("userToken"),
        },
      });
    }
  }, [localStorage.getItem("userToken"), localStorage.getItem("userEmail")]);

  //on mounting of the component
  React.useEffect(() => {
    if (localStorage.getItem("unCheckedFilters")) {
      const unCheckedLists = JSON.parse(
        localStorage.getItem("unCheckedFilters")
      );
      store.dispatch({ type: SET_UNCHECKED_LINKS, payload: unCheckedLists });
    }
    if (localStorage.getItem("settings")) {
      const storedSettings = JSON.parse(localStorage.getItem("settings"));

      setSettings({ ...storedSettings });
      store.dispatch({ type: SET_DARK_MODE, payload: storedSettings.darkMode });
      store.dispatch({ type: SET_LIST_MODE, payload: storedSettings.listMode });
      store.dispatch({ type: SET_TAGS_MODE, payload: storedSettings.tagsMode });
      store.dispatch({
        type: SET_BANNERS_MODE,
        payload: storedSettings.bannersMode,
      });
      store.dispatch({
        type: SET_MENU_ICON_MODE,
        payload: storedSettings.menuIconMode,
      });
      store.dispatch({
        type: SET_FILTER_BAR,
        payload: storedSettings.filterBar,
      });
      store.dispatch({
        type: SET_LINK_MODE,
        payload: storedSettings.linkIcons || true,
      });
      store.dispatch({
        type: SET_WINDOW_MODE,
        payload: storedSettings.windowMode,
      });
    }
    if (localStorage.getItem("AUTH_ACTIVE_SLIDE_HOME")) {
      store.dispatch({
        type: "SET_ACTIVE_SLIDE",
        payload: JSON.parse(localStorage.getItem("AUTH_ACTIVE_SLIDE_HOME")),
      });
    }
    if (localStorage.getItem("AUTH_ACTIVE_SLIDE_LISTS")) {
      store.dispatch({
        type: "SET_ACTIVE_SLIDE_LISTS",
        payload: JSON.parse(localStorage.getItem("AUTH_ACTIVE_SLIDE_LISTS")),
      });
    }
    if (localStorage.getItem("UNAUTH_ACTIVE_SLIDE")) {
      store.dispatch({
        type: "SET_UNAUTH_ACTIVE_SLIDE",
        payload: JSON.parse(localStorage.getItem("UNAUTH_ACTIVE_SLIDE")),
      });
    }
    if (localStorage.getItem("UNAUTH_ACTIVE_SLIDE_LISTS")) {
      store.dispatch({
        type: "SET_UNAUTH_ACTIVE_SLIDE_LISTS",
        payload: JSON.parse(localStorage.getItem("UNAUTH_ACTIVE_SLIDE_LISTS")),
      });
    }
    if (
      localStorage.getItem("ACTIVE_SLIDE_CATEGORY") &&
      localStorage.getItem("ACTIVE_SLIDE_CATEGORY_ARR")
    ) {
      store.dispatch({
        type: "SET_ACTIVE_SLIDE_CATEGORY",
        payload: JSON.parse(localStorage.getItem("ACTIVE_SLIDE_CATEGORY")),
        catArr: JSON.parse(localStorage.getItem("ACTIVE_SLIDE_CATEGORY_ARR")),
      });
    }
    if (localStorage.getItem("AUTH_ACTIVE_SLIDE_MOST_POPULAR")) {
      store.dispatch({
        type: "SET_ACTIVE_SLIDE_MOST_POPULAR",
        payload: JSON.parse(
          localStorage.getItem("AUTH_ACTIVE_SLIDE_MOST_POPULAR")
        ),
      });
    }
    if (localStorage.getItem("UNAUTH_ACTIVE_SLIDE_MOST_POPULAR")) {
      store.dispatch({
        type: "SET_UNAUTH_ACTIVE_SLIDE_MOST_POPULAR",
        payload: JSON.parse(
          localStorage.getItem("UNAUTH_ACTIVE_SLIDE_MOST_POPULAR")
        ),
      });
    }
    if (localStorage.getItem("AUTH_ACTIVE_SLIDE_MOST_RECENT")) {
      store.dispatch({
        type: "SET_ACTIVE_SLIDE_MOST_RECENT",
        payload: JSON.parse(
          localStorage.getItem("AUTH_ACTIVE_SLIDE_MOST_RECENT")
        ),
      });
    }
    if (localStorage.getItem("UNAUTH_ACTIVE_SLIDE_MOST_RECENT")) {
      store.dispatch({
        type: "SET_UNAUTH_ACTIVE_SLIDE_MOST_RECENT",
        payload: JSON.parse(
          localStorage.getItem("UNAUTH_ACTIVE_SLIDE_MOST_RECENT")
        ),
      });
    }
    if (
      localStorage.getItem("UNAUTH_ACTIVE_SLIDE_CATEGORY") &&
      localStorage.getItem("UNAUTH_ACTIVE_SLIDE_CATEGORY_ARR")
    ) {
      store.dispatch({
        type: "SET_UNAUTH_ACTIVE_SLIDE_CATEGORY",
        payload: JSON.parse(
          localStorage.getItem("UNAUTH_ACTIVE_SLIDE_CATEGORY")
        ),
        catArr: JSON.parse(
          localStorage.getItem("UNAUTH_ACTIVE_SLIDE_CATEGORY_ARR")
        ),
      });
    }

    window.onresize = () => {};
  }, []);

  //on changing of pathname
  const previousPathname = usePrevious(props.location.pathname);
  useEffect(() => {
    if (props.location.pathname !== "/search-result") {
      setSearchData({ ...searchData, searchValue: "" });
    }
    if (previousPathname !== props.location.pathname) {
      store.dispatch({ type: RESET_TAGS, payload: "" });
    }
  }, [props.location.pathname]);

  const onSearchValueChange = async (e) => {
    if (e.target.value) {
      props.history.push("/search-result");
    }

    props.setSearchPageNumber(1);

    store.dispatch({
      type: SET_SEARCH_VALUE,
      payload: e.target.value,
    });

    setSearchData({ ...searchData, searchValue: e.target.value });
  };

  const _toggle = (fieldName) => {
    const newSettings = { ...settings, [fieldName]: !settings[fieldName] };
    setSettings(newSettings);
    localStorage.setItem("settings", JSON.stringify(newSettings));
    if (fieldName === "darkMode") {
      if (settings[fieldName]) {
        toast.dark("Light Theme is Activated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#EDF0F5" : "#3C3C3C" },
        });
      } else {
        toast.dark("Dark Theme is Activated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#EDF0F5" : "#3C3C3C" },
        });
      }
      store.dispatch({ type: SET_DARK_MODE, payload: newSettings.darkMode });
    }
    if (fieldName === "listMode") {
      if (settings[fieldName]) {
        toast.dark("Displaying Links by Grid Activated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
      } else {
        toast.dark("Displaying Links by Rows Activated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
      }
      store.dispatch({ type: SET_LIST_MODE, payload: newSettings.listMode });
    }

    if (fieldName === "bannersMode") {
      if (settings[fieldName]) {
        toast.dark("Banner Image Deactivated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
      } else {
        toast.dark("Banner Image Activated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
      }
      store.dispatch({
        type: SET_BANNERS_MODE,
        payload: newSettings.bannersMode,
      });
    }
    if (fieldName === "linkIcons") {
      if (settings[fieldName]) {
        toast.dark("Iocn Next to Link Activated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
      } else {
        toast.dark("Icon Next to Link Deactivated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
      }
      store.dispatch({ type: SET_LINK_MODE, payload: newSettings.linkIcons });
    }
    if (fieldName === "tagsMode") {
      if (settings[fieldName]) {
        toast.dark("Tags Bar Deactivated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
      } else {
        toast.dark("Tags Bar Activated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
      }
      store.dispatch({ type: SET_TAGS_MODE, payload: newSettings.tagsMode });
    }
    if (fieldName === "windowMode") {
      if (settings[fieldName]) {
        toast.dark("Links Open In New Tab Activated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
      } else {
        toast.dark("Links Open In New Window Activated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
      }
      store.dispatch({
        type: SET_WINDOW_MODE,
        payload: newSettings.windowMode,
      });
    }
    if (fieldName === "filterBar") {
      if (settings[fieldName]) {
        toast.dark("Filter Bar Deactivated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
      } else {
        toast.dark("Filter Bar Activated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
      }
      store.dispatch({ type: SET_FILTER_BAR, payload: newSettings.filterBar });
    }
  };

  const isMobile = window.innerWidth < 600;

  const isStarActive = window.location.pathname === "/favorites";
  const isPlusActive = window.location.pathname === "/submit-link";
  const isListsActive = window.location.pathname === "/lists";

  const activeDropdownLinks = {
    isIconLibraryActive: window.location.pathname === "/icon-library",
    isAboutActive: window.location.pathname === "/about",
    isTermsActive: window.location.pathname === "/terms-and-conditions",
    isPrivacyActive: window.location.pathname === "/privacy-policy",
    isFeedbackActive: window.location.pathname === "/send-feedback",
  };

  const menuIsOn =
    window.innerWidth < 1023
      ? props.parentState.mobileMenuActive
      : !props.parentState.staticMenuInactive;

  let topBarLeft = {};

  if (iconMode) {
    topBarLeft = { left: "7px" };
  }

  // if (iconMode && !isLaptop) {
  //   topBarLeft = { left: "7px" };
  // }

  if (browserName.name === "firefox" && iconMode) {
    topBarLeft = { left: "6px" };
  }

  // const focusFun = () => {
  //   if(SearchInputRef.current){
  //   console.log("Ref content : ",SearchInputRef.current)
  //   SearchInputRef.current.focus()
  //  }
  // }
  // console.log(" Props From Search : ", props.history.location.pathname);

  const modalShow = (whichModal) => {
    setWhichmodal(whichModal);
    setShowmodal(!showModal);
  };

  const titleVisibility = () => {
    props.titleVisibility();
  };

  const switchModal = (whichModall) => {
    setPreviousModal(whichModal);
    setWhichmodal(whichModall);
  };

  const forgotPassword = () => {
    setShowmodal(false);
    setForgotPasswordModal(!forgotPasswordModal);
  };

  const resetCode = (email) => {
    setForgotPasswordModal(false);
    if (resetCodeSentModal) {
      setResetCodeSentModal(false);
    } else {
      setEmailToRenewPassword(email);
      setResetCodeSentModal(true);
    }
  };

  const popupWindow = (url, windowName, win, w, h) => {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  };

  const openNewWindow_ = (url) => {
    if (url) {
      popupWindow(
        url,
        "name",
        window,
        window.innerWidth - 450,
        window.innerHeight - 150
      );
    }
  };

  const logout = () => {
    store.dispatch({ type: SET_UPVOTES, payload: [] });
    store.dispatch({ type: SET_CAT_LIST, payload: [] });
    localStorage.removeItem("userToken");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("isSubVisible");
    localStorage.removeItem("userName");
    // localStorage.removeItem("AUTH_ACTIVE_SLIDE_LISTS");
    // localStorage.removeItem("AUTH_ACTIVE_SLIDE_HOME");
    store.dispatch({ type: SET_AUTH_USER, payload: {} });
    props.history.push("/");
    // localStorage.removeItem("isSubVisible");
    // store.dispatch({ type: SET_SUBSCRIBE_BUTTON_STATUS, payload: true });
    // props.history.push("/");
    // props.changeAuth(false);
    setIsGearActive(false);
    // localStorage.setItem("upvotedPosts", JSON.stringify([]));
    notify();
    props.titleVisibility();
  };

  const accountClick = () => {
    setIsGearActive(false);
  };

  const hideModal = () => {
    store.dispatch({ type: "SET_DELETE_MODAL", payload: false });
  };

  const notify = () =>
    toast.dark("You have logged out", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
    });

  const deleteAccount = async () => {
    setAccDelLoader(true);
    await deleteAcc()
      .then((resp) => {
        setAccDelLoader(false);
        if (resp.response.status === 200) {
          setAccDeleteStatus("deleted");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("userToken");
          props.history.push("/");
          notify("Account successfully deleted!");
        } else {
          setAccDeleteStatus("failed");
        }
      })
      .catch((err) => {
        setAccDelLoader(false);
        setAccDeleteStatus("failed");
      });
  };

  const _getSocialIcons = () => {
    getSocialIcons("")
      .then((res) => {
        setSocialIcons(res.json.socials);
        setDefaultInfo(res.json.default);
      })
      .catch((err) => {
        // handle error
      });
  };

  useEffect(() => {
    _getSocialIcons();
  }, []);

  const _filterDropdown = () => {
    return (
      <div
        style={{
          backgroundColor: darkMode
            ? "rgba(60,60,60,1)"
            : "rgba(237, 240, 245,1)",
          padding: "0px",
        }}
        className={`top-bar-more-menu ${
          darkMode ? "blurred-box" : "blurred-box-light"
        }`}
      >
        <ul
          style={{
            listStyle: "none",
            color: "#8A8A8A",
            display: "flex",
            margin: "0px",
            alignItems: "space-between",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0px",
            width: "100%",
            height: "100%",
            zIndex: 10000,
          }}
        >
          {linksData.map((sub, ind) => {
            return (
              <li
                onClick={() => {
                  window.open(sub.url, "_blank");
                }}
                key={ind}
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "space-between",
                  justifyContent: "space-between",
                  margin: "13px",
                  cursor: "pointer",
                  opacity: 1,
                  zIndex: 10000,
                }}
              >
                <span
                  style={{
                    maxWidth: "100px",
                    display: "flex",
                    fontWeight: "bold",
                  }}
                >
                  {sub.title}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <>
      {showModal ? (
        <AuthModal
          previousModal={previousModal}
          modalShow={modalShow}
          whichModal={whichModal}
          switchModal={switchModal}
          forgotPassword={forgotPassword}
          titleVisibility={titleVisibility}
          userData={props.userData}
          darkMode={darkMode}
        />
      ) : null}

      {forgotPasswordModal ? (
        <ForgotPasswordModal
          hideModal={() => setForgotPasswordModal(false)}
          darkMode={darkMode}
          forgotPassword={forgotPassword}
          resetCode={resetCode}
        />
      ) : null}

      {resetCodeSentModal ? (
        <ResetCodeSentModal
          hideModal={() => {
            setResetCodeSentModal(false);
          }}
          darkMode={darkMode}
          resetCode={resetCode}
          email={emailToRenewPassword}
        />
      ) : null}

      {props.settings.deleteModal ? (
        <Del
          darkMode={darkMode}
          accDelLoader={accDelLoader}
          deleteAccount={deleteAccount}
          accDeleteStatus={accDeleteStatus}
          setAccDeleteStatus={setAccDeleteStatus}
          hideModal={hideModal}
        />
      ) : null}

      <div className="layout-topbar" style={topBarLeft}>
        <div className="icon-left">
          <button
            className="p-link layout-menu-button"
            onClick={props.onToggleMenu}
          >
            {menuIsOn ? (
              <Icon
                name="chevron left"
                size="small"
                style={{
                  fontSize: "18px",
                  marginRight: "0",
                  marginLeft: "14px",
                  marginTop: "2px",
                  color: "grey",
                }}
              />
            ) : (
              <Icon
                name="chevron right"
                size="small"
                style={{
                  fontSize: "18px",
                  marginRight: "0",
                  marginLeft: "4px",
                  color: "grey",
                }}
              />
            )}
          </button>

          {isMobile ? null : (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.history.push("/");
                store.dispatch({ type: SEARCH_INPUT_SHOWING, payload: false });
              }}
              className="layout-logo"
            >
              <h3>PikiLinks</h3>
            </span>
          )}

          {!isMobile && (
            <div>
              <Popup
                position="bottom left"
                content={() => _filterDropdown()}
                on="click"
                className={`${
                  darkMode ? "popupAddonsDark" : "popupAddonsLight"
                }`}
                style={{
                  padding: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderStyle: "none",
                  marginTop: "15px",
                  marginLeft: "15px",
                  background: "transparent",
                }}
                trigger={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "70px",
                      paddingTop: "3px",
                      cursor: "pointer",
                    }}
                  >
                    <BsThreeDots style={{ fontSize: "20px" }} />
                  </div>
                }
              />
            </div>
          )}
          {/* left side options */}
          {isMobile && (
            <>
              <Popup
                trigger={
                  <span
                    onClick={() => {
                      props.history.push("/submit-link");
                      store.dispatch({
                        type: SEARCH_INPUT_SHOWING,
                        payload: false,
                      });
                    }}
                    tooltip="Add"
                  >
                    <i
                      className={`glyph-icon flaticon-plus ${
                        isPlusActive ? "active" : ""
                      }`}
                      style={{
                        color: "gray",
                        margin: "0px 7px 0px 7px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                }
                content="Submit Link"
                inverted
                size="mini"
              />
              <Popup
                trigger={
                  <span
                    onClick={() => {
                      store.dispatch({
                        type: SEARCH_INPUT_SHOWING,
                        payload: !props.world.searchInputShowing,
                      });
                      // setSearchInputShowing((searchInputShowing) => !searchInputShowing)
                    }}
                  >
                    <i
                      className={`glyph-icon flaticon-loupe`}
                      style={{
                        color: "grey",
                        margin: "0px 7px 0px 7px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                }
                content="Expand Search Bar"
                inverted
                size="mini"
              />
            </>
          )}
        </div>
        <div className="icon-center">
          <span className="icon-center-content">
            {props.world.searchInputShowing ? (
              <InputText
                autoFocus
                style={{ outline: "none" }}
                id="searchbox"
                className="searchbox"
                type="text"
                placeholder="Search Links..."
                onChange={onSearchValueChange}
                value={searchData.searchValue}
              />
            ) : (
              <div
                style={
                  isTabletOrMobileDevice
                    ? { margin: "0 auto" }
                    : { margin: "0 auto" }
                }
                onClick={() => props.history.push("/")}
              >
                <Image
                  src={
                    props.world && props.world.logo && props.world.logo.logo
                      ? props.world.logo.logo
                      : ""
                  }
                  size="mini"
                />
              </div>
            )}
          </span>
        </div>
        <div className={isMobile ? "icon-right mobile" : "icon-right"}>
          {/* Just for spacing */}
          {isMobile && searchInputShowing && (
            <div style={{ width: "20px" }}></div>
          )}

          {props.world.searchInputShowing && isMobile ? null : (
            <>
              {!isMobile && (
                <>
                  <Popup
                    trigger={
                      <span
                        onClick={() => {
                          store.dispatch({
                            type: SEARCH_INPUT_SHOWING,
                            payload: !props.world.searchInputShowing,
                          });
                          // setSearchInputShowing((searchInputShowing) => !searchInputShowing)
                        }}
                      >
                        <i
                          className={`glyph-icon flaticon-loupe`}
                          style={{ color: "grey", cursor: "pointer" }}
                        ></i>
                      </span>
                    }
                    content="Expand Search Bar"
                    inverted
                    size="mini"
                  />
                  <Popup
                    trigger={
                      <span
                        onClick={() => {
                          props.history.push("/submit-link");
                          store.dispatch({
                            type: SEARCH_INPUT_SHOWING,
                            payload: false,
                          });
                        }}
                        tooltip="Add"
                      >
                        <i
                          className={`glyph-icon flaticon-plus ${
                            isPlusActive ? "active" : ""
                          }`}
                          style={{ color: "grey", cursor: "pointer" }}
                        ></i>
                      </span>
                    }
                    content="Submit Link"
                    inverted
                    size="mini"
                  />
                </>
              )}
              <Popup
                trigger={
                  <span
                    onClick={() => {
                      console.log(
                        "consent:::",
                        localStorage.getItem("cookie-consent")
                      );
                      const cookieConsent = JSON.parse(
                        localStorage.getItem("cookie-consent")
                      );
                      if (cookieConsent && cookieConsent.necessary) {
                        props.history.push("/favorites");
                        // store.dispatch({ type: RESET_TAGS, payload: "" });
                        store.dispatch({
                          type: SEARCH_INPUT_SHOWING,
                          payload: false,
                        });
                      } else {
                        toast.dark(
                          "Accept necessary cookies to access favorites page",
                          {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            style: {
                              backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
                            },
                          }
                        );
                      }
                    }}
                  >
                    <i
                      className={`glyph-icon flaticon-star ${
                        isStarActive ? "active" : ""
                      }`}
                      style={{
                        color: "grey",
                        margin: isMobile
                          ? "0px 7px 0px 7px"
                          : "0px 0px 0px 0px",
                      }}
                    />
                  </span>
                }
                content="Favorites"
                inverted
                size="mini"
              />

              <Popup
                trigger={
                  <span
                    onClick={() => {
                      props.history.push("/lists");
                      store.dispatch({
                        type: SEARCH_INPUT_SHOWING,
                        payload: false,
                      });
                      // store.dispatch({ type: SET_LIST_MODE, payload: true });
                    }}
                  >
                    <i
                      className={`glyph-icon flaticon-list ${
                        isListsActive ? "active" : ""
                      }`}
                      style={{
                        color: "grey",
                        margin: isMobile
                          ? "0px 7px 0px 7px"
                          : "0px 0px 0px 0px",
                      }}
                    />
                  </span>
                }
                content="Lists"
                inverted
                size="mini"
              />

              <div className="gear-icon-container">
                {!isGearActive ? (
                  <div className="tooltip-container">
                    <div className="tooltip-arrow left"></div>
                    <div className="tooltip-arrow right"></div>
                    <div className="tooltip">Settings</div>
                  </div>
                ) : null}

                <Dropdown
                  open={isGearActive}
                  onOpen={() => setIsGearActive(true)}
                  onBlur={() => setIsGearActive(false)}
                  className="gear-dropdown"
                  direction="left"
                  icon=""
                  trigger={
                    <i
                      className={`glyph-icon flaticon-settings ${
                        isGearActive ? "active" : ""
                      }`}
                      style={{ color: "grey" }}
                    />
                  }
                >
                  <Dropdown.Menu>
                    <div className="loginRegister">
                      {localStorage.getItem("userToken") ? (
                        <>
                          <div>
                            <Link
                              to="/account"
                              onClick={accountClick}
                              style={{
                                color: settings.darkMode ? "white" : "black",
                              }}
                            >
                              ACCOUNT
                            </Link>
                          </div>
                          <div>
                            {/* <a href="/" onClick={logout} style={{ color: settings.darkMode ? "white" : "black", textDecoration: "none", fontWeight: "bold" }}>
															LOGOUT
														</a> */}
                            <Link
                              to="/"
                              onClick={logout}
                              style={{
                                color: settings.darkMode ? "white" : "black",
                              }}
                            >
                              LOGOUT
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <div onClick={() => modalShow("login")}>
                            <span
                              style={{
                                color: settings.darkMode ? "white" : "black",
                              }}
                            >
                              LOGIN
                            </span>
                          </div>
                          <div onClick={() => modalShow("register")}>
                            <span
                              style={{
                                color: settings.darkMode ? "white" : "black",
                              }}
                            >
                              REGISTER
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                    {dropdownButtons.map((button, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          onClick={() => {
                            _toggle(button.fieldName);
                            if (button.fieldName === "refreshMenuOrder") {
                              // props.RefreshMenu()
                              const cookieConsent = JSON.parse(
                                localStorage.getItem("cookie-consent")
                              );
                              if (!cookieConsent.necessary) {
                                toast.dark(
                                  "Accept necessary cookies to reorder menu items",
                                  {
                                    position: "top-right",
                                    autoClose: 2000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    style: {
                                      backgroundColor: darkMode
                                        ? "#3C3C3C"
                                        : "#EDF0F5",
                                    },
                                  }
                                );
                              } else {
                                props.setPageNo(1);
                                store.dispatch({
                                  type: REFRESH_MENU_ORDER,
                                  payload: true,
                                });
                                toast.dark(
                                  "Order of the Category has been Reset",
                                  {
                                    position: "top-right",
                                    autoClose: 2000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    style: {
                                      backgroundColor: darkMode
                                        ? "#3C3C3C"
                                        : "#EDF0F5",
                                    },
                                  }
                                );
                              }
                            }
                          }}
                        >
                          {index === 2 ? (
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {settings[button.fieldName] ? (
                                <BiWindowOpen
                                  style={{
                                    fontSize: "18px",
                                    color: darkMode ? "white" : "black",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                />
                              ) : (
                                <GoBrowser
                                  style={{
                                    fontSize: "18px",
                                    color: darkMode ? "white" : "black",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                />
                              )}
                              <span style={{ marginLeft: "5px" }}>
                                {button.text}
                              </span>
                              <b style={{ paddingLeft: "4px" }}>
                                {settings[button.fieldName]
                                  ? button.labels[0]
                                  : button.labels[1]}
                              </b>
                            </span>
                          ) : index === 3 ? (
                            <>
                              <i
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "2px",
                                }}
                                className={`${
                                  settings[button.fieldName]
                                    ? button.icon[1]
                                    : button.icon[0]
                                }`}
                              />
                              <span style={{ marginLeft: "-3px" }}>
                                {button.text}
                              </span>
                              <b>
                                {settings[button.fieldName]
                                  ? button.labels[0]
                                  : button.labels[1]}
                              </b>
                            </>
                          ) : index === 4 ? (
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {settings[button.fieldName] ? (
                                <MdTune
                                  style={{
                                    fontSize: "18px",
                                    color: darkMode ? "white" : "black",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                />
                              ) : (
                                <MdTune
                                  style={{
                                    fontSize: "18px",
                                    color: darkMode ? "white" : "black",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                />
                              )}
                              <span style={{ marginLeft: "5px" }}>
                                {button.text}
                              </span>
                              <b style={{ paddingLeft: "4px" }}>
                                {settings[button.fieldName]
                                  ? button.labels[0]
                                  : button.labels[1]}
                              </b>
                            </span>
                          ) : (
                            <>
                              <i
                                style={{ marginRight: "10px" }}
                                className={`${
                                  settings[button.fieldName]
                                    ? button.icon[1]
                                    : button.icon[0]
                                }`}
                              />
                              {button.text}
                              <b>
                                {settings[button.fieldName]
                                  ? button.labels[0]
                                  : button.labels[1]}
                              </b>
                            </>
                          )}
                        </Dropdown.Item>
                      );
                    })}
                    <Dropdown.Divider />
                    {dropdownLinks.map((link, index) => {
                      if (link.fieldName === "isPWALinkActive") {
                        if (!proposalEvent) {
                          return null;
                        }

                        return (
                          <Dropdown.Item
                            onClick={() => {
                              store.dispatch({
                                type: PROMPT_ADD_TO_HOME_SCREEN,
                              });
                              proposalEvent.prompt();
                            }}
                            key={index}
                          >
                            <i
                              style={{ marginLeft: "3px", marginRight: "5px" }}
                              className={`${
                                activeDropdownLinks[link.fieldName]
                                  ? link.icon[1]
                                  : link.icon[0]
                              }`}
                            />
                            <span style={{ marginLeft: "3px" }}>
                              {link.text}
                            </span>
                          </Dropdown.Item>
                        );
                      }

                      if (link.fieldName === "uninstallPwa") {
                        if (!props.pwaInstall.pwaWindowOn) {
                          return null;
                        }

                        return (
                          <Dropdown.Item
                            onClick={() => {
                              store.dispatch({
                                type: UNINSTALL_PWA_MODAL,
                                payload: true,
                              });
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                            key={index}
                          >
                            <MdDeleteForever style={{ fontSize: "20px" }} />
                            <i
                              style={{ marginLeft: "3px", marginRight: "5px" }}
                              className={`${link.icon[1]}`}
                            />
                            <span style={{ marginLeft: "3px" }}>
                              {link.text}
                            </span>
                          </Dropdown.Item>
                        );
                      }

                      return link.value === "sendFeedback" ? (
                        <Dropdown.Item
                          onClick={() => {
                            store.dispatch({
                              type: SET_FEEDBACK_MODAL,
                              payload: true,
                            });
                          }}
                        >
                          <i
                            className={`${
                              activeDropdownLinks[link.fieldName]
                                ? link.icon[1]
                                : link.icon[0]
                            }`}
                            style={{ marginLeft: "3px", marginRight: "5px" }}
                          />
                          <span style={{ marginLeft: "3px" }}>{link.text}</span>
                        </Dropdown.Item>
                      ) : link.value === "iconLibrary" ? (
                        <Dropdown.Item
                          onClick={() => {
                            store.dispatch({
                              type: "SET_ICONS_LIBRARY_MODAL",
                              payload: true,
                            });
                          }}
                        >
                          <i
                            className={`${
                              activeDropdownLinks[link.fieldName]
                                ? link.icon[1]
                                : link.icon[0]
                            }`}
                            style={{ marginLeft: "3px", marginRight: "5px" }}
                          />
                          <span style={{ marginLeft: "3px" }}>{link.text}</span>
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          onClick={() => props.history.push(link.link)}
                          key={index}
                        >
                          {link.value === "howTo" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <FaChalkboardTeacher
                                style={{
                                  fontSize: "18px",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                              />
                              <span style={{ marginLeft: "10px" }}>
                                {link.text}
                              </span>
                            </div>
                          ) : link.value === "platforms" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <DiHtml5Multimedia
                                style={{
                                  fontSize: "18px",
                                  color: darkMode ? "white" : "black",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                              />
                              <span style={{ marginLeft: "10px" }}>
                                {link.text}
                              </span>
                            </div>
                          ) : (
                            <>
                              <i
                                className={`${
                                  activeDropdownLinks[link.fieldName]
                                    ? link.icon[1]
                                    : link.icon[0]
                                }`}
                                style={{
                                  marginLeft: "3px",
                                  marginRight: "5px",
                                }}
                              />
                              <span style={{ marginLeft: "3px" }}>
                                {link.text}
                              </span>
                            </>
                          )}
                        </Dropdown.Item>
                      );
                    })}
                    <Dropdown.Divider />
                    {/* <Dropdown.Item> */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: 1,
                        paddingLeft: "7px",
                        paddingRight: "7px",
                        paddingTop: "0px",
                        paddingBottom: "10px",
                      }}
                    >
                      {!isEmpty(socialIcons) &&
                        socialIcons.map((link, index) => {
                          return (
                            <>
                              <span
                                style={{
                                  display: "flex",
                                  flex: 0.25,
                                  padding: "5px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <a
                                  className={
                                    darkMode
                                      ? "menu-social-icon-dark"
                                      : "menu-social-icon-light"
                                  }
                                  href={link.url}
                                  target="_blank"
                                >
                                  <i
                                    style={{
                                      fontSize:
                                        link.fieldName === "reddit"
                                          ? "25px"
                                          : "20px",
                                      cursor: "pointer",
                                    }}
                                    className={`${link.icon}`}
                                  />
                                </a>
                              </span>
                            </>
                          );
                        })}
                      {isEmpty(socialIcons) && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              flex: 0.25,
                              padding: "5px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <a
                              className={
                                darkMode
                                  ? "menu-social-icon-dark"
                                  : "menu-social-icon-light"
                              }
                              href={
                                defaultInfo && defaultInfo.url
                                  ? defaultInfo.url
                                  : "#"
                              }
                              target="_blank"
                            >
                              {defaultInfo && defaultInfo.text
                                ? defaultInfo.text
                                : ""}
                            </a>
                          </span>
                        </div>
                      )}
                    </div>
                    {/* </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

AppTopbar.defaultProps = {
  onToggleMenu: null,
};

AppTopbar.propTypes = {
  onToggleMenu: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  world: state.world,
  pwaInstall: state.pwaInstall,
});

export default connect(mapStateToProps, {})(withRouter(AppTopbar));
