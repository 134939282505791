import React, { useRef, useMemo, useState, useEffect } from "react";

//from packages
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//components
import SortBy from "../../components/SortBy/SortBy";
import Loader from "../../components/Loader/Loader";
import PostCard from "../../components/PostCard/PostCard";

//apis
import { searchPosts } from "../../api/api";

//utils
import store from "../../redux/store";

//types
import { UPDATE_TAGS, SET_POST_CARD_DATA_MODAL } from "../../redux/types";
import { Dropdown } from "primereact/dropdown";
import { Button } from "semantic-ui-react";

//MetaDecorator
import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";

const sortByOptions = [
  { name: "Newest", code: "Newest", index: 1 },
  { name: "Random", code: "Random", index: 2 },
  { name: "Popularity", code: "Popularity", index: 3 },
  { name: "Oldest", code: "Oldest", index: 4 },
];

function SearchResult(props) {
  const [searchResults, setSearchResults] = React.useState([]);
  const loader = React.useRef(null);
  const [pageNo, setPageNo] = React.useState(1);
  const [isFinished, setIsFinished] = React.useState(false);
  const [sortBy, setSortBy] = useState(sortByOptions[0]);

  React.useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .classList.add("search-result-page");
    document.getElementsByTagName("body")[0].removeAttribute("id");

    if (localStorage.getItem("searchResultsSortBy")) {
      setSortBy(JSON.parse(localStorage.getItem("searchResultsSortBy")));
    }

    const controller = new AbortController();
    const signal = controller.signal;

    const { searchKey } = props;
    if (!searchKey) {
      props.history.push("/");
      return;
    }
    setSearchResults([]);
    setIsFinished(false);
    // setPageNo(0);
    props.setSearchPageNumber(0);
    if (searchKey && props.searchPageNumber > 0) {
      searchPosts(
        `query=${searchKey}&paged=${props.searchPageNumber}&${`sort_by=${
          sortBy.code ? sortBy.code.toLowerCase() : ""
        }`}`,
        signal
      )
        .then((res) => {
          res = res.json;
          // switch(sortBy.code){
          //   case "Random":
          //   const RandomResult = [...res].sortBy((a,b)=>{
          //     return Math.random() - 0.5;
          //   })
          //   setSearchResults(RandomResult)
          //   default :
          if (sortBy.code === "Random") {
            const RandomResult = [...res].sort((a, b) => {
              return Math.random() - 0.5;
            });
            setSearchResults(RandomResult);
          } else {
            setSearchResults(res);
          }

          // }
          // setSearchResults(res);
          // setPageNo((pageNo) => pageNo + 1);
          props.setSearchPageNumber((pageNumber) => pageNumber + 1);
          if (res.length < 1) {
            setIsFinished(true);
          }
        })
        .catch((err) => {
          // console.log(err)
        });
    }

    return () => {
      controller.abort();
      document
        .getElementsByTagName("body")[0]
        .classList.remove("search-result-page");
    };
  }, [props.searchKey]);

  React.useEffect(() => {
    if (!props.searchKey) {
      props.history.push("/");
    }
  }, []);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ piki_page: "search" });
  }, [props.history]);

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if ((props.searchPageNumber > 1) & !isFinished) {
      searchPosts(
        `query=${props.searchKey}&paged=${props.searchPageNumber}&${`sort_by=${
          sortBy.code ? sortBy.code.toLowerCase() : ""
        }`}`,
        signal
      )
        .then((res) => {
          res = res.json;
          if (res.length < 1) {
            setIsFinished(true);
          }

          if (sortBy.code === "Random") {
            const RandomResult = [...res].sort((a, b) => {
              return Math.random() - 0.5;
            });

            // if(sortBy.code === "Popular")

            // alert(" Inside Random : ",RandomResult)

            setSearchResults([...searchResults, ...RandomResult]);
          } else {
            setSearchResults([...searchResults, ...res]);
          }
        })
        .catch((err) => {
          // console.log(err)
        });
    }
    return () => {
      controller.abort();
    };
  }, [props.searchPageNumber]);

  //for observer
  React.useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, [props.searchKey]);
  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      // setPageNo((pageNo) => pageNo + 1);
      props.setSearchPageNumber((pageNumber) => pageNumber + 1);
    }
  };

  //update the tags on load
  useEffect(() => {
    searchResults.forEach((post) => {
      store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
    });
  }, [searchResults]);

  const _sortByChange = (e) => {
    setSortBy({ name: e, code: e });
    // setSearchResults([])
    // console.log(" Random Thing : ", e.target.value);
    if (e === "Random") {
      const FilteredPost = [...searchResults].sort((a, b) => {
        return Math.random() - 0.5;
      });
      setSearchResults(FilteredPost);
    }

    props.setSearchPageNumber(0);
    setIsFinished(false);
    localStorage.setItem(
      "searchResultsSortBy",
      JSON.stringify({ name: e, code: e })
    );
  };

  const _openPostModal = (id) => {
    store.dispatch({
      type: SET_POST_CARD_DATA_MODAL,
      payload: { postCardModal: true, activePostCardModal: id },
    });
  };

  const PostsList = useMemo(() => {
    return searchResults
      .filter((post) => {
        return props.activeTag
          ? post.tags.find((myTag) => myTag.term_id === props.activeTag.term_id)
          : true;
      })
      .filter((v, i, a) => a.findIndex((t) => t.ID === v.ID) === i)
      .sort((a, b) => {
        switch (sortBy.code) {
          case "Popularity":
            return b.up_vote_count - a.up_vote_count;
          case "Random":
            return;
          case "Newest":
            return new Date(b.post_date) - new Date(a.post_date);
          case "Oldest":
            return new Date(a.post_date) - new Date(b.post_date);
          default:
            return new Date(b.post_date) - new Date(a.post_date);
        }
      })
      .map((post, index) => {
        let filteredPostsArr = searchResults
          .filter((post) => {
            return props.activeTag
              ? post.tags.find(
                  (myTag) => myTag.term_id === props.activeTag.term_id
                )
              : true;
          })
          .filter((v, i, a) => a.findIndex((t) => t.ID === v.ID) === i)
          .sort((a, b) => {
            switch (sortBy.code) {
              case "Popularity":
                return b.up_vote_count - a.up_vote_count;
              case "Random":
                return;
              case "Newest":
                return new Date(b.post_date) - new Date(a.post_date);
              case "Oldest":
                return new Date(a.post_date) - new Date(b.post_date);
              default:
                return new Date(b.post_date) - new Date(a.post_date);
            }
          });
        return (
          <PostCard
            postsArr={filteredPostsArr}
            onOpen={() => _openPostModal(post.ID)}
            key={index}
            upvotedMe={(store.getState().world.upvotedPosts || []).find(
              (postt) => postt.ID === post.ID
            )}
            postData={post}
            postId={index}
          />
        );
      });
  }, [searchResults, sortBy, props.activeTag]);

  return (
    <div className="p-grid p-fluid dashboard page-search-result">
      <MetaDecorator metaDataPerRoute={metaDecoratorData.search_result} />
      {/* <button onClick={() => { setPageNo({ ...pageNo, value: pageNo.value + 1 }) }} >addp ages</button> */}
      <div className="p-col-12 p-lg-12">
        <div
          className={`p-col-12 p-md-12 ${
            props.settings.darkMode ? "page-header" : "page-header-light"
          }`}
        >
          <i class="glyph-icon flaticon-loupe" style={{ color: "grey" }}></i>
          <span style={{ color: "grey" }}>
            Search Results<b>{props.searchKey ? ": " + props.searchKey : ""}</b>
          </span>
        </div>
        <div
          className="p-col-12 p-md-12"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <span>
            <CustomDropdown
              handleOnClick={_sortByChange}
              sortByValue={sortBy.code ? sortBy.code : "Sort By"}
              dropdownName="Sort By"
              dropdownOptions={sortByOptions}
            />
            {/* <Dropdown optionLabel="name" placeholder="Sort By" onChange={_sortByChange} options={sortByOptions} value={sortBy} /> */}
          </span>
        </div>
      </div>
      {PostsList}

      {isFinished ? (
        <div className="p-col-12 p-lg-12">
          <div
            style={{ textAlign: "center" }}
            className="p-text-center card summary"
          >
            No Results
          </div>
        </div>
      ) : (
        <div
          ref={loader}
          style={{ display: "block" }}
          className="loading-more-wrapper"
        >
          <Loader loadingMore={true} />
        </div>
      )}
    </div>
  );
}

SearchResult.prototypes = {
  searchKey: PropTypes.string.isRequired,
  activeTag: PropTypes.object,
};

const mapsStateToProps = (state) => ({
  searchKey: state.world.searchKey,
  activeTag: state.world.activeTag,
  settings: state.settings,
});

export default connect(mapsStateToProps)(withRouter(SearchResult));
