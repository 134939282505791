import React, { useRef } from "react";
import "./newRandomLink.scss";
import { Icon } from "semantic-ui-react";
import store from "../../redux/store";
import { connect, useSelector } from "react-redux";
import { OpenInBrowser } from "@material-ui/icons";
import { isEmpty } from "../../validation/validation";
import { RiForbidLine } from "react-icons/ri";
import { toast } from "react-toastify";
import openpopup from "../../assets/img/openpopup.png";

const NewRandomLink = (props) => {
  let containerRef = useRef();
  let [dMotion, setDmotion] = React.useState(true);
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [isRandomActive, setIsRandomActive] = React.useState(false);

  const _flotifyOnHover = () => {
    document.getElementById("floaty").classList.add("is-active");
  };

  const _flotifyOnHoverRemove = () => {
    document.getElementById("floaty").classList.remove("is-active");
  };

  const _containerClick = () => {
    document.getElementById("container").classList.remove("is-active");
  };

  const _floatifyClick = () => {
    document.getElementById("floaty").classList.add("is-active");
    if (document.getElementById("floaty-list").style.opacity === "0") {
      document.getElementById("floaty-list").style.opacity = "1";
    } else {
      document.getElementById("floaty-list").style.opacity = "1";
    }
  };

  const _popupMenu = () => {
    _floatifyClick();
    let container = document.querySelector("#container");
    let itemPopup = document.querySelector(".item-popup");
    console.log("container::", container, container.style.visiblity, itemPopup);
    if (container && container.style.visibility === "visible") {
      container.style.visibility = "hidden";
      itemPopup.classList.remove("item-popup-active");
      itemPopup.classList.remove("item-popup-content");
      setIsRandomActive(false);
    } else {
      container.style.visibility = "visible";
      itemPopup.classList.add("item-popup-active");
      itemPopup.classList.add("item-popup-content");
      setIsRandomActive(true);
    }
  };

  const activeteAll_ = () => {
    let allCats = props.categories.categories.map((data) => {
      return data.term_id;
    });
    if (isEmpty(props.isAuth)) {
      store.dispatch({ type: "SET_RANDOM_LINK_CATEGORIES", payload: allCats });
    } else {
      store.dispatch({
        type: "SET_AUTH_RANDOM_LINK_CATEGORIES",
        payload: allCats,
      });
    }
  };

  const deactiveteAll_ = () => {
    let allCats = [];
    if (isEmpty(props.isAuth)) {
      store.dispatch({ type: "SET_RANDOM_LINK_CATEGORIES", payload: allCats });
    } else {
      store.dispatch({
        type: "SET_AUTH_RANDOM_LINK_CATEGORIES",
        payload: allCats,
      });
    }
  };

  const _changeLinkStatus = (cat, item) => {
    setDmotion(false);

    if (isEmpty(props.isAuth)) {
      // for un-auth menu
      let isCatActive = props.randomLinkCategories.find((id) => {
        return id === cat.term_id;
      });
      let updateCatList = [...props.randomLinkCategories];
      if (isCatActive) {
        // remove from cat active list
        updateCatList = [...props.randomLinkCategories].filter((data) => {
          return data !== cat.term_id;
        });
      } else {
        // add new cat active list
        updateCatList.push(cat.term_id);
      }
      store.dispatch({
        type: "SET_RANDOM_LINK_CATEGORIES",
        payload: updateCatList,
      });
    } else {
      // for auth menu
      let isCatActive = props.authRandomLinkCategories.find((id) => {
        return id === cat.term_id;
      });

      let updateCatList = [...props.authRandomLinkCategories];

      if (isCatActive) {
        // remove from cat active list
        updateCatList = [...props.authRandomLinkCategories].filter((data) => {
          return data !== cat.term_id;
        });
      } else {
        // add new cat active list
        updateCatList.push(cat.term_id);
      }
      store.dispatch({
        type: "SET_AUTH_RANDOM_LINK_CATEGORIES",
        payload: updateCatList,
      });
    }
  };

  let soredDraggableArry = localStorage.getItem("draggableArry")
    ? JSON.parse(localStorage.getItem("draggableArry"))
    : props.categories.categories;
  let customersLists =
    Object.keys(props.user).length !== 0
      ? props.categories.authCategories
      : soredDraggableArry;

  if (dMotion) {
    var myDiv = document.getElementById("floaty-list");
    if (myDiv) {
      myDiv.scrollTop = myDiv.scrollHeight;
    }
  }

  let list1 = [...customersLists].filter(
    (data) => data.label !== "All Categories"
  );
  let list2 = (
    isEmpty(props.isAuth)
      ? [...props.randomLinkCategories]
      : [...props.authRandomLinkCategories]
  ).filter((data) => data !== 884886);
  let allCatActive = list1.length === list2.length || false;
  let allCatDeActive = isEmpty(props.isAuth)
    ? isEmpty(props.randomLinkCategories)
    : isEmpty(props.authRandomLinkCategories);

  return (
    <>
      <div className="popup-wrapper">
        <div className="item-popup">
          <div className="container" id="container">
            <div
              id="floaty"
              onMouseEnter={_flotifyOnHover}
              onMouseLeave={_flotifyOnHoverRemove}
              className="floaty"
              onclick="myFunction()"
              style={{
                marginRight: "-10px",
                bottom:
                  window.innerHeight > 1000
                    ? "-10.0rem"
                    : window.innerHeight > 900
                    ? "-9.8rem"
                    : window.innerHeight > 800
                    ? "-8.8rem"
                    : window.innerHeight > 700
                    ? "-7.8rem"
                    : window.innerHeight > 600
                    ? "-6.8rem"
                    : window.innerHeight > 500
                    ? "-5.8rem"
                    : window.innerHeight > 400
                    ? "-4.8rem"
                    : "-3.5rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <ul
                className="floaty-list"
                id="floaty-list"
                ref={containerRef}
                style={{
                  maxHeight: `${window.innerHeight - 13}px`,
                  overflowY: "scroll",
                  alignSelf: "right",
                  right: 0,
                  bottom: "0px",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  scrollbarWidth: "thin",
                }}
              >
                {/* <div className="random-link-list" style={{ maxHeight: `${window.innerHeight - 30}px` }}> */}
                {[...customersLists]
                  .reverse()
                  .filter((data) => data.label !== "All Categories")
                  .map((data, index) => {
                    let isActive = (
                      isEmpty(props.isAuth)
                        ? props.randomLinkCategories
                        : props.authRandomLinkCategories
                    ).find((id) => id === data.term_id);

                    return (
                      <li
                        key={index}
                        id={`floaty-list-${index}`}
                        onClick={() =>
                          _changeLinkStatus(data, `float-${index}`)
                        }
                        className={`floaty-list-item${
                          isActive ? "" : " deselected"
                        }`}
                        style={{
                          backgroundColor: props.darkMode ? "#3C3C3C" : null,
                          marginLeft: "240px",
                          overflowX: "visible",
                        }}
                      >
                        <span
                          className="floaty-list-item-label"
                          style={{
                            color:
                              data.color && data.color.length > 3
                                ? data.color
                                : "#8A8A8A",
                            backgroundColor: props.darkMode
                              ? "#3C3C3C"
                              : "#EDF0F5",
                            overflowX: "visible",
                            zIndex: 10000001,
                            position: "absolute",
                          }}
                        >
                          {data.label}
                          {isActive ? null : (
                            <span
                              style={{
                                color: "#8a8a8a",
                                marginLeft: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              Excluded
                            </span>
                          )}
                        </span>
                        {data.icon ? (
                          <img className="cat-icon" src={data.icon} />
                        ) : (
                          <div
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <i
                              class="cat-icon"
                              className="flaticon-picture-1 flaticon-icon"
                              style={{
                                color: isActive ? "#8A8A8A" : "#D0D0D0",
                                top: "30%",
                                left: "30%",
                                position: "absolute",
                              }}
                            ></i>
                          </div>
                        )}
                      </li>
                    );
                  })}

                <li
                  className={`floaty-list-item ${
                    allCatActive ? "deselected" : ""
                  }`}
                  onClick={deactiveteAll_}
                  style={{
                    backgroundColor: props.darkMode ? "#3C3C3C" : null,
                    marginLeft: "240px",
                  }}
                >
                  <span
                    className="floaty-list-item-label grey"
                    style={{
                      backgroundColor: props.darkMode ? "#3C3C3C" : "#EDF0F5",
                    }}
                  >
                    DeActivate All
                  </span>
                  {/* <RiForbidLine className="cat-icon all-icon" /> */}
                  <img
                    className="cat-icon all-icon"
                    src="https://www.freeiconspng.com/uploads/no-image-icon-32.png"
                  />
                </li>
                <li
                  className={`floaty-list-item ${
                    allCatActive ? "" : "deselected"
                  }`}
                  onClick={activeteAll_}
                  style={{
                    backgroundColor: props.darkMode ? "#3C3C3C" : null,
                    marginLeft: "240px",
                  }}
                >
                  <span
                    className="floaty-list-item-label grey"
                    style={{
                      backgroundColor: props.darkMode ? "#3C3C3C" : "#EDF0F5",
                    }}
                  >
                    Activate All
                  </span>
                  <img
                    className="cat-icon all-icon"
                    src="https://app.pikilinks.com/wp-content/uploads/2021/05/all-cat.png"
                  />
                </li>
                {/* </div> */}
              </ul>
              {/* <div
                id={`control-buttons`}
                style={{
                  height: "100%",
                  borderWidth: "3px",
                  borderColor: "red",
                  display: "flex",
                  width: "100%",
                  alignItems: "cemnter",
                  justifyContent: "center",
                }}
              >
                {props.randomLinkBrowserOpenMode ? (
                  // class="toggle-btn"
                  <div
                    className="toggle-btn"
                    style={{
                      cursor: "pointer",
                      backgroundColor: props.darkMode ? "#3C3C3C" : null,
                    }}
                  >
                    <span
                      className="floaty-btn-label2"
                      style={{
                        backgroundColor: props.darkMode ? "#3C3C3C" : "#EDF0F5",
                      }}
                    >
                      Open in browser
                    </span>
                    <label
                      className="tog"
                      style={{ cursor: "pointer" }}
                      onClick={props.setRandomLinkOpenType}
                    >
                      <span
                        style={{
                          color: "#808080",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <OpenInBrowser style={{ fontSize: "22px" }} />
                      </span>
                    </label>
                  </div>
                ) : (
                  // class="toggle-btn"
                  <div
                    className="toggle-btn"
                    style={{
                      cursor: "pointer",
                      backgroundColor: props.darkMode ? "#3C3C3C" : null,
                    }}
                  >
                    <span
                      className="floaty-btn-label2"
                      style={{
                        backgroundColor: props.darkMode ? "#3C3C3C" : "#EDF0F5",
                      }}
                    >
                      Open by page
                    </span>
                    <label
                      className="tog"
                      style={{ cursor: "pointer" }}
                      onClick={props.setRandomLinkOpenType}
                    >
                      <span>
                        <i
                          style={{ color: "#808080" }}
                          className={`fas fa-external-link-alt`}
                        />
                      </span>
                    </label>
                  </div>
                )}

                <div
                  className="floaty-btn"
                  onClick={_floatifyClick}
                  style={{ backgroundColor: props.darkMode ? "#3C3C3C" : null }}
                >
                  <span
                    className="floaty-btn-label"
                    style={{
                      backgroundColor: props.darkMode ? "#3C3C3C" : "#EDF0F5",
                    }}
                  >
                    Exclude Settings
                  </span>
                  <img
                    className="gear-icon"
                    src="https://app.pikilinks.com/wp-content/uploads/2021/05/gear-grey1.png"
                  />
                </div>
              </div> */}
              {/* </div> */}
            </div>
          </div>
          <div
            className="popup-content"
            // onClick={() => {
            //   if (allCatDeActive) {
            //     toast.dark(
            //       <b style={{ width: "400px" }}>
            //         Activate at least one category!
            //       </b>,
            //       {
            //         position: toast.POSITION.TOP_RIGHT,
            //         autoClose: 2000,
            //         hideProgressBar: true,
            //         style: {
            //           backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
            //         },
            //       }
            //     );
            //     return;
            //   }
            //   props.onRandomClick();
            // }}
            style={{ cursor: "pointer" }}
          >
            {isRandomActive && (
              <div
                className="toggle-menus"
                style={{ backgroundColor: darkMode ? "#272727" : "#fff" }}
              >
                <div
                  onClick={() => props.setRandomLinkOpenType()}
                  className={`toggle-menus__item ${
                    props.randomLinkBrowserOpenMode ? "toggle-menu-active" : ""
                  } `}
                  style={{
                    border: darkMode
                      ? "0.5px solid #000"
                      : "0.5px solid lightgrey",
                  }}
                >
                  <OpenInBrowser style={{ fontSize: "18px" }} />
                </div>
                <div
                  onClick={() => props.setRandomLinkOpenType()}
                  className={`toggle-menus__item ${
                    props.randomLinkBrowserOpenMode ? "" : "toggle-menu-active"
                  }`}
                  style={{
                    border: darkMode
                      ? "0.5px solid #000"
                      : "0.5px solid lightgrey",
                  }}
                >
                  <img
                    src={openpopup}
                    style={{
                      width: "1.15em",
                      height: "1.15em",
                    }}
                  />
                </div>
              </div>
            )}
            <a
              style={{
                backgroundColor: props.darkMode ? "#6b6b6b" : null,
                borderColor: props.darkMode ? "#6b6b6b" : null,
              }}
              // onClick={props.onRandomClick}
              title=""
              className="popup-button"
              data-wpel-link="internal"
              onClick={_popupMenu}
            >
              {props.randomPageLoading ? (
                <Icon loading name="spinner" style={{ marginLeft: "4px" }} />
              ) : isRandomActive ? (
                <svg
                  fill="#ffffff"
                  height="10px"
                  width="10px"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <g>
                    <g>
                      <polygon
                        points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 
			                  512,452.922 315.076,256"
                      />
                    </g>
                  </g>
                </svg>
              ) : (
                <Icon name="shuffle" style={{ marginLeft: "4px" }} />
              )}
            </a>{" "}
            <p
              onClick={() => {
                if (allCatDeActive) {
                  toast.dark(
                    <b style={{ width: "400px" }}>
                      Activate at least one category!
                    </b>,
                    {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 2000,
                      hideProgressBar: true,
                      style: {
                        backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
                      },
                    }
                  );
                  return;
                }
                _popupMenu();
                props.onRandomClick();
              }}
              style={{ marginTop: "3px" }}
              id="random-post-btn"
            >
              RANDOM LINK
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(NewRandomLink);
