import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router-dom";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { detect } from "detect-browser";
import store from "../../redux/store";
import { Parallax, Background } from "react-parallax";
import Loader from "../../components/Loader/Loader";
import isEmpty from "../../utils/isEmpty";

const CoverSlider = (props) => {
  const [state, setState] = React.useState({
    nav1: null,
  });
  const [activeDot, setActiveDot] = React.useState(0);

  const slider1 = React.useRef();
  // let activeSlidee = props.activeSlide
  // 	? parseInt(props.activeSlide)
  // 	: props.location.pathname === "/lists"
  // 	? parseInt(localStorage.getItem("AUTH_ACTIVE_SLIDE_LISTS"))
  // 	: parseInt(localStorage.getItem("AUTH_ACTIVE_SLIDE_HOME"));
  React.useEffect(() => {
    setActiveDot(props.activeSlide);
    slider1.current.slickGoTo(parseInt(props.activeSlide));
  });

  React.useEffect(() => {
    setState({
      nav1: slider1.current,
    });
  }, []);

  const { nav1 } = state;
  const next = () => {
    slider1.current.slickNext();
    // slider1.current.slickGoTo(2);
  };

  const previous = () => {
    slider1.current.slickPrev();
  };

  const settings = {
    initialSlide: parseInt(props.activeSlide),
    dots: true,
    infinite: true,
    className: "CoverBanner",
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prev, next) => {
      setActiveDot(next);
      props.setActiveSlide(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          padding: "0px",
          margin: "0px",
          zIndex: 1000,
          // borderStyle: "solid",
          // height: "100px",
          marginBottom: props.isMobile ? "10px" : "5px",
          // paddingTop: "10px"
        }}
      >
        <ul
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 0px 0px 0px",
          }}
        >
          <button
            onClick={previous}
            className="slick-custom-arrow"
            style={{ marginRight: "-0px" }}
          >
            <AiOutlineLeft style={{ fontSize: "13px" }} />
          </button>
          <div
            style={{
              paddingLeft: "5px",
              paddingRight: "5px",
              display: "flex",
              marginTop: "-13px",
            }}
          >
            <div
              onClick={() => {
                slider1.current.slickGoTo(0);
                // props.onSlideChange(0);
              }}
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor:
                  activeDot === 0
                    ? props.darkMode
                      ? "lightgray"
                      : "gray"
                    : props.darkMode
                    ? "gray"
                    : "lightgray",
                margin: "5px",
                cursor: "pointer",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor:
                  activeDot === 0
                    ? props.darkMode
                      ? "lightgray"
                      : "gray"
                    : props.darkMode
                    ? "gray"
                    : "lightgray",
              }}
            ></div>
            <div
              onClick={() => {
                slider1.current.slickGoTo(1);
                // props.onSlideChange(1);
              }}
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor:
                  activeDot === 1
                    ? props.darkMode
                      ? "lightgray"
                      : "gray"
                    : props.darkMode
                    ? "gray"
                    : "lightgray",
                margin: "5px",
                cursor: "pointer",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor:
                  activeDot === 1
                    ? props.darkMode
                      ? "lightgray"
                      : "gray"
                    : props.darkMode
                    ? "gray"
                    : "lightgray",
              }}
            ></div>
            <div
              onClick={() => {
                slider1.current.slickGoTo(2);
                // props.onSlideChange(2);
              }}
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor:
                  activeDot === 2
                    ? props.darkMode
                      ? "lightgray"
                      : "gray"
                    : props.darkMode
                    ? "gray"
                    : "lightgray",
                margin: "5px",
                cursor: "pointer",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor:
                  activeDot === 2
                    ? props.darkMode
                      ? "lightgray"
                      : "gray"
                    : props.darkMode
                    ? "gray"
                    : "lightgray",
              }}
            ></div>
          </div>
          {/* {dots} */}
          <button onClick={next} className="slick-custom-arrow">
            <AiOutlineRight style={{ fontSize: "13px" }} />
          </button>
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <>
        <div
          style={{
            width: "8px",
            height: "8px",
            color: "blue",
            borderRadius: "50%",
            borderStyle: "solid",
            borderColor: "#999A9B",
            // paddingLeft: i === "1" ? "-100px" : "0px",
            borderWidth: "1px",
            cursor: "pointer",
            zIndex: 100000000,
            backgroundColor: i === activeDot ? "#999A9B" : "transparent",
            // marginRight: i === 2 ? "-20px" : i === 1 ? "-10px" : "0px"
            marginLeft: i === 0 ? "22px" : i === 1 ? "12px" : "2px",
          }}
        ></div>
      </>
    ),
  };
  return (
    <>
      {props.slides ? (
        <Slider
          asNavFor={nav1}
          ref={(c) => (slider1.current = c)}
          {...settings}
          className={
            // props.isMobile
            //   ? data.image
            //     ? "CoverBannerMobile"
            //     : "EmptyCoverBannerMobile"
            //   : data.image
            //   ? "CoverBanner"
            //   : "EmptyCoverBanner"
            props.isMobile ? "CoverBannerMobile" : "CoverBanner"
          }
        >
          {!isEmpty(props.slides) ? (
            props.slides.map((data, index) => {
              let dataUrl = data && data.url ? data.url : "";
              let filteredURL = "";
              if (dataUrl.length > 0) {
                filteredURL = dataUrl.replace("https://", "");
              }

              return (
                <>
                  <div
                    className={
                      data.image
                        ? "CoverBannerInnerContainer"
                        : "CoverBannerInnerContainerEmpty"
                    }
                    key={index}
                  >
                    <Parallax
                      className="cover-parent"
                      style={{ minWidth: "100%" }}
                      blur={{ min: -5, max: 5 }}
                      strength={200}
                      bgClassName="parallexComp"
                      bgImageStyle={{ width: "100%" }}
                      bgImage={data.image}
                    >
                      <div
                        className="art-by"
                        style={{
                          display: filteredURL.length === 0 ? "none" : "",
                        }}
                        onClick={() => {
                          if (filteredURL) {
                            window.open(data.url, "_blank");
                          }
                        }}
                      >
                        <span className="title">ART BY:</span>
                        <strong className="author">
                          {" "}
                          {filteredURL
                            ? filteredURL.length === 0
                              ? "unknown"
                              : filteredURL
                            : "unknown"}
                        </strong>
                      </div>
                      <div
                        style={{
                          minHeight: props.isMobile ? "150px" : "37vh",
                          maxHeight: props.isMobile ? "150px" : "37vh",
                          width: "100%",
                          backgroundSize: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <h2>
                          {data.title ? (
                            data.title
                          ) : (
                            <span style={{ color: "rgba(0,0,0,0)" }}>
                              Loading...
                            </span>
                          )}
                        </h2>
                      </div>
                    </Parallax>
                  </div>
                </>
              );
            })
          ) : (
            <>
              <div style={{ marginTop: "-20px" }}>
                <Parallax
                  className="cover-parent"
                  style={{ width: "100%" }}
                  blur={{ min: -5, max: 5 }}
                  strength={200}
                  bgClassName="parallexComp"
                  bgImageStyle={{ width: "100%" }}
                  // bgImage={data.image}
                >
                  <div className="art-by" style={{ display: "none" }}>
                    <span className="title">ART BY:</span>
                    <strong className="author"> {"unknown"}</strong>
                  </div>
                  <div
                    style={{
                      minHeight: props.isMobile ? "150px" : "20vh",
                      maxHeight: props.isMobile ? "150px" : "20vh",
                      backgroundSize: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                    }}
                  >
                    <h2
                      style={{
                        fontWeight: "bold",
                        fontSize: props.isMobile ? "20px" : "26px",
                      }}
                    >
                      {props.cattitle ? (
                        props.cattitle
                      ) : (
                        <span style={{ color: "rgba(0,0,0,0)" }}>
                          Loading...
                        </span>
                      )}
                    </h2>
                  </div>
                </Parallax>
              </div>
            </>
          )}
        </Slider>
      ) : (
        <h2>Banners not available</h2>
      )}
    </>
  );
};

export default withRouter(CoverSlider);
