import React, { useRef, useState, useEffect } from "react";

//from packages
import store from "../../redux/store";
import { toast } from "react-toastify";
import { Input, Label, Dropdown, Icon } from "semantic-ui-react";
import { NavLink, withRouter, Route, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import ReCAPTCHAV2 from "react-google-recaptcha";
import Multiselect from "multiselect-react-dropdown";
import { Form, Message, Modal } from "semantic-ui-react";
import {
  checkUrlValidation,
  getIcons,
  getPostsByTag,
  getPointsData,
  verifyPublishedLinks,
} from "../../api/api";
//components
import TagsInput from "./TagsInput";
import LinkTypeInput from "./LinkTypeInput";
//apis
import { addPost, getLinkTypeData, getDefaultPoints } from "../../api/api";
import { BiAddToQueue } from "react-icons/bi";

//utils
import { validateAddPostInputs } from "../../validation/validation";

//MetaDecorator
import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";
import { useMediaQuery } from "react-responsive";
import _ from "lodash";
import isEmpty from "../../utils/isEmpty";
import { SET_IS_DROP_ON } from "../../redux/types";
import { prev } from "dom7";
import TextareaAutosize from "react-textarea-autosize";
import IconLibraryModal from "../../components/IconLibrary/IconLibraryModal";

const ErrorBody = (props) => {
  if (!props.children) return null;
  return (
    <Label basic color="red" pointing="below">
      {props.children}
    </Label>
  );
};

const dataModel = {
  email: "",
  title: "",
  url: "",
  linkType: "",
  category: "",
  tags: [],
  recaptchaVerified: false,
  "g-recaptcha-response": "",
};

const MultiSelectOptions = [
  {
    cat: "Group 1",
    key: "Option 1",
  },
  {
    cat: "Group 1",
    key: "Option 2",
  },
  {
    cat: "Group 1",
    key: "Option 3",
  },
  {
    cat: "Group 2",
    key: "Option 4",
  },
  {
    cat: "Group 2",
    key: "Option 5",
  },
  {
    cat: "Group 2",
    key: "Option 6",
  },
  {
    cat: "Group 2",
    key: "Option 7",
  },
];

function AddPost(props) {
  const captchaRef = useRef(null);
  const dropRef = useRef(null);
  const tagsRef = useRef(null);
  const [linkTypeOptions, setLinkTypeOptions] = useState([]);
  const [selectedParentLinks, setSelectedParentLinks] = useState([]);
  const [selectedChildLinks, setSelectedChildLinks] = useState([]);
  const [addPostData, setAddPostData] = useState({ ...dataModel });
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [linkType, setLinkType] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const [dm, setDm] = useState("dark");
  const [lastTagImage, setLastTagImage] = useState("");
  const [tagImageLoader, setTagImageLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAutoCompleteOn, setIsAutoCompleteOn] = useState(false);
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [pointsData, setPointsData] = useState({
    submitted: "0",
    published: "0",
    total: "0",
  });

  const [iconLibraryModal, setIconLibraryModal] = useState(false);
  const options = store
    .getState()
    .categories.categories.filter((data, index) => data.term_id !== 884886)
    .map((cat, index) => {
      if (cat.term_id) {
        return {
          key: index,
          text: cat.name,
          value: cat.term_id,
          image: { avatar: true, src: cat.icon ? cat.icon : "" },
        };
      }
    });
  const _getPointsData = (query, signal) => {
    getPointsData(query, signal)
      .then((res) => {
        if (res.response.ok) {
          setPointsData({
            submitted:
              res.json && res.json.total_submitted_link
                ? res.json.total_submitted_link
                : "0",
            published:
              res.json && res.json.total_published_link
                ? res.json.total_published_link
                : "0",
            total:
              res.json && res.json.total_earned ? res.json.total_earned : "0",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const _getDefaultPointsData = (query, signal) => {
    getDefaultPoints(query, signal)
      .then((res) => {
        if (res.response.ok) {
          setPointsData({
            total:
              res.json && res.json.total_earned ? res.json.total_earned : "0",
            published:
              res.json && res.json.published_link_point
                ? res.json.published_link_point
                : "0",
            submitted:
              res.json && res.json.submitted_link_point
                ? res.json.submitted_link_point
                : "0",
          });
        }
      })
      .catch((err) => {
        console.log("", err);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    _getDefaultPointsData("", controller.signal);

    return () => {
      controller.abort();
    };
  }, [localStorage.getItem("userToken")]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      let checkboxes = document.getElementById("checkboxes");

      if (
        isMenuOpen &&
        dropRef.current &&
        !dropRef.current.contains(e.target)
      ) {
        checkboxes.style.display = "none";
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      let autoCompleteTags = document.getElementById("autoComplete");

      if (
        isAutoCompleteOn &&
        tagsRef.current &&
        !tagsRef.current.contains(e.target)
      ) {
        autoCompleteTags.style.display = "none";
        setIsAutoCompleteOn(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isAutoCompleteOn]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("add-post-page");
    document.getElementsByTagName("body")[0].removeAttribute("id");

    return () => {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("add-post-page");
    };
  }, []);

  const getTagIcon_ = (tag) => {
    let filterQuery = `tag=${tag}`;
    setTagImageLoader(true);
    getPostsByTag(filterQuery)
      .then((res) => {
        setTagImageLoader(false);
        if (res.response.ok) {
          if (res.json.tag && res.json.tag.url) {
            setLastTagImage(res.json.tag.url);
          } else {
            setLastTagImage("");
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const isIphone5 = useMediaQuery({ query: "(max-width: 320px)" });

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      return;
    }
    const iconBox = document.createElement("div");
    iconBox.innerHTML =
      '<i aria-hidden="true" class="list icon add-category-icon"></i>';
    let currentIcon = document.getElementsByClassName("add-category-icon")[0];
    if (currentIcon) {
      currentIcon.remove();
    }
    let activeCat = options.filter((op) => op.value === category);
    if (activeCat && activeCat[0] && activeCat[0].image) {
      iconBox.innerHTML = `<img src="${activeCat[0].image.src}" style="width:15px;height:15px;margin-right:13px;" class="list icon add-category-icon" />`;
    }
    const categoryDiv =
      document.getElementsByClassName("addpost-category")[0].childNodes[1];
    categoryDiv.prepend(iconBox.firstChild);
  }, [category]);

  const _getLinkTypeData = () => {
    getLinkTypeData()
      .then((res) => {
        if (res.response.ok) {
          setLinkTypeOptions(_.values(res.json));
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  React.useEffect(() => {
    _getLinkTypeData();
  }, []);

  const _addPostDataChange = (e, { value }) => {
    setErrors({ ...errors, [e.target.name]: "" });
    if (e.target.name === "email") {
      setEmail(value);
    }

    if (e.target.name === "title") {
      setTitle(value);
    }

    if (e.target.name === "linkType") {
      setLinkType(value);
    }

    if (e.target.name === "url") {
      setUrl(value);
    }

    setSubmitted(false);
  };

  const _setSelectedParentLinks = (links) => {
    setSelectedParentLinks(links);
  };

  const _setSelectedChildLinks = (links) => {
    setSelectedChildLinks(links);
  };

  const _categoryChange = (e, { value }) => {
    setCategory(value);
    setErrors({ ...errors, category: "" });
    setSubmitted(false);
  };

  const _setTags = (tags) => {
    getTagIcon_(tags[tags.length - 1]);
    setTags(tags);
  };
  const _tagsChange = (tags) => {
    setErrors({ ...errors, tags: "" });
    getTagIcon_(tags[tags.length - 1]);
    setTags(tags);
    setSubmitted(false);
  };

  const _showResponse = (res) => {
    setErrors({ ...errors, "g-recaptcha-response": "" });
    setRecaptchaResponse(res);
    setAddPostData({
      ...addPostData,
      recaptchaVerified: true,
      "g-recaptcha-response": res,
    });
  };

  const _expireRecaptcha = (res) => {
    setRecaptchaVerified(false);
    setRecaptchaResponse("");
  };

  const _submit = () => {
    const { isValid, errors } = validateAddPostInputs({
      email: localStorage.getItem("userEmail") || email,
      title: title,
      url: url,
      category: category,
      tags: [...tags],
      recaptchaVerified: recaptchaVerified,
      "g-recaptcha-response": `${recaptchaResponse}`,
    });

    if (!localStorage.getItem("userToken")) {
      return;
    }
    if (!isValid) {
      setErrors(errors);
      return;
    } else {
      setErrors({ email: "", title: "", url: "", category: "", tags: "" });
    }
    setSubmit(true);
    addPost({
      email: localStorage.getItem("userEmail") || email,
      title: title,
      url: url,
      link_types: [...new Set([...selectedParentLinks, ...selectedChildLinks])],
      category: category,
      tags: tags,
      "g-recaptcha-response": recaptchaResponse,
    })
      .then((res) => {
        setSubmit(false);
        if (res.response.ok) {
          toast.dark(
            <b style={{ width: "400px" }}>Successfully submitted!</b>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
              hideProgressBar: true,
              style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
            }
          );
          setEmail("");
          setTitle("");
          setUrl("");
          setSelectedChildLinks([]);
          setSelectedParentLinks([]);
          setCategory("");
          setTags([]);
          setRecaptchaVerified(false);
          setRecaptchaResponse("");
          setLastTagImage("");
          if (!localStorage.getItem("userToken")) {
            let currentLists = [];
            let storedLinks = localStorage.getItem("submittedLinks");
            if (storedLinks) {
              currentLists = JSON.parse(localStorage.getItem("submittedLinks"));
            }

            currentLists.push(res.json.post);
            localStorage.setItem(
              "submittedLinks",
              JSON.stringify(currentLists)
            );
            setPointsData({
              ...pointsData,
              submitted: parseInt(pointsData.submitted) + 1,
            });
          } else {
            setPointsData({
              ...pointsData,
              // submitted: parseInt(pointsData.submitted) + 1
            });
          }
        } else {
          setErrors(
            res.json.errors || {
              ...errors,
              "g-recaptcha-response": res.json.message,
            }
          );
          setRecaptchaVerified(false);
          // setAddPostData({ ...addPostData, recaptchaVerified: false })
        }
      })
      .catch((err) => {
        toast.error(<b>{err}</b>, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 2000,
          hideProgressBar: true,
        });
      });
    // .then(() => {
    // 	captchaRef && captchaRef.current.reset();
    // });
  };

  const _urlFocusOut = (e) => {
    checkUrlValidation(`?url=${e.target.value}`)
      .then((res) => {
        if (res.errors.length > 0 && res.errors.url) {
          setErrors({
            ...errors,
            url: res.errors.url,
          });
        } else {
          setErrors({
            ...errors,
            url: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const _recaptchaVerifyCallback = (res) => {
    if (res) {
      setErrors({ ...errors, "g-recaptcha-response": "" });
      // console.log('recaptcha response : ',res)
      setRecaptchaVerified(true);
      setRecaptchaResponse(res);
      // setAddPostData({
      //   ...addPostData,
      //   recaptchaVerified: true,
      //   "g-recaptcha-response": res,
      // })
    }
  };
  // console.log(" Process Env Key : ",process.env.REACT_APP_RECAPTCHA_SITE_KEY)
  // console.log(" Recaptcha status : ",addPostData.recaptchaVerified)
  // console.log(" store property : ",store.getState().settings.darkMode)
  const _onLinkTypeChange = (e, parent_id, children) => {
    let parentId = parent_id;
    let childrenIds = [];
    _.values(children).forEach((element) => {
      return childrenIds.push(element.term_id);
    });
    let isParentAlreadyExists = selectedParentLinks.filter(
      (p) => p === parent_id
    );

    if (isEmpty(isParentAlreadyExists)) {
      setSelectedParentLinks([...selectedParentLinks, parentId]);
      setSelectedChildLinks([...selectedChildLinks, ...childrenIds]);
    } else {
      let updatedList = selectedParentLinks.filter((d, ind) => {
        return d === parent_id ? null : parent_id;
      });
      let unique = updatedList.filter((x) => x !== null);
      setSelectedParentLinks(unique);
      let uniqueChild = _.xor(selectedChildLinks, childrenIds);
      setSelectedChildLinks(uniqueChild);
    }
  };

  const _onChildrenLinkTypeChange = (e, child_id, parent_id, all_childrens) => {
    // let parentLink = linkTypeOptions.filter((data,index)=>{
    // 	return data.term_id === parent_id
    // })

    let isParentAlreadyExists = selectedParentLinks.filter(
      (p, ind) => p === parent_id
    );
    let isChildAlreadyExist = selectedChildLinks.filter(
      (ch, ind) => ch === child_id
    );
    // console.log("onChildrenLinkTypeChange1", child_id, parent_id, isChildAlreadyExist);

    if (isEmpty(isChildAlreadyExist)) {
      setSelectedChildLinks([...selectedChildLinks, child_id]);
      setSelectedParentLinks([...selectedParentLinks, parent_id]);
    } else {
      // if (!isEmpty(isParentAlreadyExists)) {
      // 	return;
      // } else {

      let count = 0;
      for (let i = 0; i < selectedChildLinks.length - 1; i++) {
        all_childrens.forEach((element, index) => {
          if (selectedChildLinks[i] === element.term_id) {
            count += 1;
          }
        });
      }
      if (count === 0) {
        let updatedParentList = selectedParentLinks.filter(
          (p, i) => p !== parent_id
        );
        setSelectedParentLinks(updatedParentList);
      }

      let updatedList = selectedChildLinks.filter((d, ind) => {
        return d === child_id ? null : child_id;
      });
      let unique = updatedList.filter((x) => x !== null);
      setSelectedChildLinks(unique);
      // }
    }
  };

  const _deleteLinkItem = (id) => {
    let isParent = selectedParentLinks.filter((par, index) => par === id);
    let isChild = selectedChildLinks.filter((ch, index) => ch === id);
    if (!isEmpty(isParent)) {
      // is a parent
      let updatedList = selectedParentLinks.filter((par, ind) => par !== id);
      setSelectedParentLinks(updatedList);
    } else if (!isEmpty(isChild)) {
      // is child
      let updatedList = selectedChildLinks.filter((ch, ind) => ch !== id);
      setSelectedChildLinks(updatedList);
    }
  };

  let isFormValid = email.length > 1;
  let isMobile = window.innerWidth < 600;

  return (
    <div className="p-grid p-fluid dashboard page-contact">
      <MetaDecorator metaDataPerRoute={metaDecoratorData.add_post} />
      <div className="p-col-12 p-lg-12">
        <div className="card card-w-title">
          {localStorage.getItem("userToken") ? (
            <h1>Submit Link</h1>
          ) : (
            <h1>Login to Submit a Link</h1>
          )}
          <div className="p-grid ">
            <div className="p-lg-8 add-post-page">
              {submitted ? (
                <Message info>
                  <Message.Header>Link successfully submitted!</Message.Header>
                </Message>
              ) : null}
              <br />
              <div className="p-col-12 p-md-12">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: darkMode ? "white" : "black",
                      padding: "5px",
                    }}
                  >
                    {localStorage.getItem("userToken")
                      ? "Earn Points"
                      : "Login to Earn Points"}
                  </span>
                </div>
                <div
                  className="earn-points"
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "10px 0px 40px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: isMobile ? "10px" : "15px",
                      backgroundColor: darkMode ? "#CCCCCC" : "white",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        color: "gray",
                        fontSize: isMobile ? "11px" : "14px",
                      }}
                      className="head"
                    >
                      Submitted Link
                    </span>
                    <span
                      style={{
                        color: darkMode ? "black" : "black",
                        fontSize: isMobile ? "20px" : "22px",
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                      className="point"
                    >
                      {pointsData.submitted}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: isMobile ? "10px" : "15px",
                      backgroundColor: darkMode ? "#CCCCCC" : "white",
                      width: "100%",
                      borderLeftStyle: "solid",
                      borderRightStyle: "solid",
                      borderLeftWidth: "2px",
                      borderRightWidth: "2px",
                      borderColor: darkMode ? "#3C3C3C" : "#EDF0F5",
                    }}
                  >
                    <span
                      style={{
                        color: "gray",
                        fontSize: isMobile ? "11px" : "14px",
                      }}
                      className="head"
                    >
                      Published Link
                    </span>
                    <span
                      style={{
                        color: darkMode ? "black" : "black",
                        fontSize: isMobile ? "20px" : "22px",
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                      className="point"
                    >
                      {pointsData.published}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: isMobile ? "10px" : "15px",
                      backgroundColor: darkMode ? "#CCCCCC" : "white",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        color: "gray",
                        fontSize: isMobile ? "11px" : "14px",
                      }}
                      className="head"
                    >
                      Total Points
                    </span>
                    <span
                      style={{
                        color: darkMode ? "black" : "black",
                        fontSize: isMobile ? "20px" : "22px",
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                      className="point"
                    >
                      {localStorage.getItem("userToken")
                        ? pointsData.total
                        : "-"}
                    </span>
                  </div>
                </div>

                <Form.Input
                  icon="at"
                  iconPosition="left"
                  fluid
                  value={localStorage.getItem("userEmail") || email}
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="title">Email</span>
                      <ErrorBody>
                        {errors.email && errors.email.toUpperCase()}
                      </ErrorBody>
                    </div>
                  }
                  placeholder="Your Email Address"
                  onChange={_addPostDataChange}
                  name="email"
                  disabled={localStorage.getItem("userEmail") ? true : false}
                />
              </div>
              <div className="p-col-12 p-md-12 textarea">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="title">Description</span>
                  <ErrorBody>
                    {errors.title && errors.title.toUpperCase()}
                  </ErrorBody>
                </div>
                <div
                  className={`${
                    darkMode ? "textarea-input-dark" : "textarea-input"
                  }`}
                >
                  <i aria-hidden="true" class="pencil alternate big icon"></i>
                  <TextareaAutosize
                    className="t-area"
                    minRows={1}
                    maxRows={50}
                    value={title.length > 250 ? title.slice(0, 250) : title}
                    onChange={(e) => {
                      if (e.target.value.length < 251) {
                        setTitle(e.target.value);
                      }
                    }}
                    placeholder="Description"
                    style={{ backgroundColor: "red" }}
                  />
                  <span>{title ? title.length : "0"} / 250</span>
                </div>
              </div>

              <div className="p-col-12 p-md-12">
                <Form.Input
                  fluid
                  icon="linkify"
                  iconPosition="left"
                  value={url}
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="title">URL</span>
                      <ErrorBody>
                        {errors.url && errors.url.toUpperCase()}
                      </ErrorBody>
                    </div>
                  }
                  placeholder="Formatted Link URL"
                  onChange={_addPostDataChange}
                  name="url"
                />
              </div>

              <div
                ref={dropRef}
                className="p-col-12 p-md-12"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="title">Types</span>
                  <ErrorBody>
                    {errors.link_types && errors.link_types.toUpperCase()}
                  </ErrorBody>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: props.settings.darkMode
                      ? "#cccccc"
                      : "white",
                    zIndex: 1,
                  }}
                >
                  <div style={{ maxWidth: "36px", minWidth: "35px" }}>
                    <div
                      name="staylinked"
                      className={`tags-input-icon`}
                      style={{
                        marginLeft: "10px",
                        color: isMenuOpen ? "rgba(0,0,0,0.8)" : "gray",
                        fontSize: "18px",
                      }}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M256 256c-13.47 0-26.94-2.39-37.44-7.17l-148-67.49C63.79 178.26 48 169.25 48 152.24s15.79-26 22.58-29.12l149.28-68.07c20.57-9.4 51.61-9.4 72.19 0l149.37 68.07c6.79 3.09 22.58 12.1 22.58 29.12s-15.79 26-22.58 29.11l-148 67.48C282.94 253.61 269.47 256 256 256zm176.76-100.86z"></path>
                        <path d="M441.36 226.81L426.27 220l-38.77 17.74-94 43c-10.5 4.8-24 7.19-37.44 7.19s-26.93-2.39-37.42-7.19l-94.07-43L85.79 220l-15.22 6.84C63.79 229.93 48 239 48 256s15.79 26.08 22.56 29.17l148 67.63C229 357.6 242.49 360 256 360s26.94-2.4 37.44-7.19l147.87-67.61c6.81-3.09 22.69-12.11 22.69-29.2s-15.77-26.07-22.64-29.19z"></path>
                        <path d="M441.36 330.8l-15.09-6.8-38.77 17.73-94 42.95c-10.5 4.78-24 7.18-37.44 7.18s-26.93-2.39-37.42-7.18l-94.07-43L85.79 324l-15.22 6.84C63.79 333.93 48 343 48 360s15.79 26.07 22.56 29.15l148 67.59C229 461.52 242.54 464 256 464s26.88-2.48 37.38-7.27l147.92-67.57c6.82-3.08 22.7-12.1 22.7-29.16s-15.77-26.07-22.64-29.2z"></path>
                      </svg>
                    </div>
                    {/* <Icon name="staylinked" className="tags-input-icon" style={{ marginLeft: "10px", color: isMenuOpen ? "rgba(0,0,0,0.8)" : "gray", fontSize: "18px" }} /> */}
                  </div>
                  <LinkTypeInput
                    setSelectedChildLinks={_setSelectedChildLinks}
                    setSelectedParentLinks={_setSelectedParentLinks}
                    selectedChildLinks={selectedChildLinks}
                    selectedParentLinks={selectedParentLinks}
                    onLinkTypeChange={_onLinkTypeChange}
                    isMenuOn={isMenuOpen}
                    setIsMenuOn={(ee) => setIsMenuOpen(ee)}
                    onChildrenLinkTypeChange={_onChildrenLinkTypeChange}
                    linkTypeOptions={linkTypeOptions}
                    deleteLinkItem={_deleteLinkItem}
                    darkMode={props.settings.darkMode}
                  />
                </div>
              </div>

              <div className="p-col-12 p-md-12">
                <Form.Select
                  selectOnBlur={false}
                  fluid
                  // selection
                  className="addpost-category"
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="title">Categories</span>
                      <ErrorBody>
                        {errors.category && errors.category.toUpperCase()}
                      </ErrorBody>
                    </div>
                  }
                  options={options}
                  placeholder="Choose a Category"
                  onChange={_categoryChange}
                  value={category}
                  name="category"
                />
              </div>
              <div ref={tagsRef} className="p-col-12 p-md-12">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="title">Tags</span>
                  <ErrorBody>
                    {errors.tags && errors.tags.toUpperCase()}
                  </ErrorBody>
                </div>
                <TagsInput
                  isAutoCompleteOn={isAutoCompleteOn}
                  setIsAutoCompleteOn={setIsAutoCompleteOn}
                  setFrom={setFrom}
                  from={from}
                  setTo={setTo}
                  to={to}
                  tagImageLoader={tagImageLoader}
                  lastTagImage={lastTagImage}
                  darkMode={props.settings.darkMode}
                  setTags={_setTags}
                  value={tags}
                  onChange={_tagsChange}
                  setIconModal={setIconLibraryModal}
                />
              </div>

              <div className="p-col-12 p-md-12">
                {(localStorage.getItem("userEmail") || email) &&
                url &&
                category &&
                tags ? (
                  <div
                    style={{
                      width: "250px",
                      margin: "auto",
                      opacity:
                        (localStorage.getItem("userEmail") || email) &&
                        url &&
                        category &&
                        tags
                          ? 1
                          : 0,
                    }}
                  >
                    <ErrorBody>
                      {errors["g-recaptcha-response"] &&
                        errors["g-recaptcha-response"].toUpperCase()}
                    </ErrorBody>
                    <ReCAPTCHAV2
                      theme={props.settings.darkMode ? `dark` : `light`}
                      ref={captchaRef}
                      sitekey={`6LeT7R4aAAAAANggb_D-U_4DQIHo0DIgFpZ07984`}
                      onChange={_showResponse}
                      onExpired={_expireRecaptcha}
                    />
                  </div>
                ) : null}
              </div>

              <div className="p-col-12 p-md-12">
                <Form.Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "0px",
                    backgroundColor:
                      title.length < 1 || category.length < 1 || submit
                        ? "gray"
                        : "black",
                  }}
                  disabled={
                    title.length < 1 ||
                    category.length < 1 ||
                    submit ||
                    tags.length < 1 ||
                    !localStorage.getItem("userToken")
                  }
                  primary
                  fluid
                  onClick={_submit}
                >
                  <BiAddToQueue
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  />
                  {submit ? <b>Submitting...</b> : <b>Submit</b>}
                </Form.Button>
              </div>
              <Modal.Content>
                {" "}
                <br />
                <div className="centered">
                  <NavLink to="/privacy">
                    <b>Privacy Policy</b>
                  </NavLink>{" "}
                  <b>|</b>
                  <NavLink to="/terms">
                    {" "}
                    <b>Terms and Conditions</b>
                  </NavLink>
                </div>
              </Modal.Content>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  categories: state.categories,
});

export default withRouter(connect(mapStateToProps, {})(AddPost));
