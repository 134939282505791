import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import SubmissionTableHeader from "./SubmissionTableHeader";
import SubmissionTable from "./SubmissionTable";
import SubmissionPagination from "./SubmissionPagination";
import {
  getPointsData,
  getSubmissionTableData,
  getSubPoints,
} from "../../api/api";
import isEmpty from "../../utils/isEmpty";
import SubmissionModal from "./SubmissionModal";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";

const dropdownOptions = [
  {
    name: "Submissions",
    code: "submission",
    index: 1,
  },
  {
    name: "Upvotes",
    code: "upvote",
    index: 2,
  },
];

function Submissions(props) {
  let isMobile = window.innerWidth < 600;
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [pointsData, setPointsData] = useState({
    submitted: "0",
    published: "0",
    total: "0",
  });
  const [submissionData, setSubmissionData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(1);
  const [sortBy, setSortBy] = useState("");
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [submissionModalData, setSubmissionModalData] = useState({
    showModal: false,
    id: "",
  });
  const [tableType, setTableType] = useState(dropdownOptions[0].code);
  const [submissionPoints, setSubmissionPoints] = useState({});

  const _getPointsData = (query, signal) => {
    getPointsData(query, signal)
      .then((res) => {
        if (res.response.ok) {
          setPointsData({
            submitted:
              res.json && res.json.total_submitted_link
                ? res.json.total_submitted_link
                : "0",
            published:
              res.json && res.json.total_published_link
                ? res.json.total_published_link
                : "0",
            total:
              res.json && res.json.total_earned ? res.json.total_earned : "0",
          });
        }
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  const _getSubmissionPoints = (signal) => {
    getSubPoints("", signal)
      .then((res) => {
        if (res.response.ok) {
          setSubmissionPoints(res.json);
        }
      })
      .catch((err) => {
        //handle error
      });
  };

  const _getSubmissionsData = (query, signal) => {
    setLoading(true);
    getSubmissionTableData(query, signal)
      .then((res) => {
        if (res.response.ok) {
          setLoading(false);
          setSubmissionData([...res.json]);
          setTotalCount(res.response.headers.get("X-WP-Total"));
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const controller = new AbortController();
    if (localStorage.getItem("userToken")) {
      let filterQuery = `?paged=${activePage}&per_page=${itemsPerPage}`;
      if (!isEmpty(sortBy)) {
        filterQuery += `&sort_by=${sortBy}`;
      }
      if (!isEmpty(filter)) {
        filterQuery += `&filter=${filter}`; //all , submitted , published , rejected , upvote , super_vote
      }
      if (!isEmpty(search)) {
        filterQuery += `&query=${search}`;
      }

      if (tableType) {
        filterQuery += `&type=${tableType}`;
      }
      _getSubmissionsData(filterQuery, controller.signal);
      _getSubmissionPoints(controller.signal);
    }
  }, [activePage, sortBy, filter, search, tableType]);

  useEffect(() => {
    const controller = new AbortController();
    if (localStorage.getItem("userToken")) {
      _getPointsData("", controller.signal);
    }
  }, []);

  const _onDropdownChange = (data) => {
    // handle on dropdown change
    let activeSubmission = dropdownOptions.filter((ass) => ass.name === data);
    if (activeSubmission && activeSubmission[0] && activeSubmission[0].code)
      setTableType(activeSubmission[0].code);
    setFilter("all");
  };

  return !localStorage.getItem("userToken") ? (
    <Route>
      <Redirect to="/" />
    </Route>
  ) : (
    <>
      <div
        className="p-grid p-fluid dashboard page-contact"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "20px",
        }}
      >
        <div
          className="card card-w-title"
          style={{
            width: isMobile ? "100%" : "75%",
            boxShadow: "none",
            paddingLeft: isMobile ? "0px" : "",
          }}
        >
          <div className="p-col-12 p-md-12">
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderStyle: "solid",
                borderColor: "rgba(138, 138, 138,0.7)",
                borderWidth: "1px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                borderBottomWidth: "0px",
                minHeight: "90px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: "gray",
                    fontSize: isMobile ? "11px" : "15px",
                    fontWeight: "bold",
                  }}
                  className="head"
                >
                  Total Points
                </span>
                <span
                  style={{
                    color: darkMode ? "white" : "black",
                    fontSize: isMobile ? "20px" : "22px",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }}
                  className="point"
                >
                  {submissionPoints && submissionPoints.total_points
                    ? submissionPoints.total_points
                    : "0"}
                </span>
              </div>
            </div>
            <div
              className="earn-points"
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0px 0px 40px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 0px",
                  backgroundColor: "transparent",
                  borderStyle: "solid",
                  borderColor: "rgba(138, 138, 138,0.7)",
                  borderWidth: "1px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "8px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 15px 15px 15px",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1px",
                    borderBottomColor: "rgba(138, 138, 138,0.7)",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontSize: isMobile ? "11px" : "13px",
                      fontWeight: "bold",
                    }}
                    className="head"
                  >
                    Submitted Links
                  </span>
                  <span
                    style={{
                      color: darkMode ? "white" : "black",
                      fontSize: isMobile ? "20px" : "22px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                    className="point"
                  >
                    {submissionPoints && submissionPoints.total_submitted_link
                      ? submissionPoints.total_submitted_link
                      : "0"}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px 15px 0px 15px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontSize: isMobile ? "11px" : "13px",
                      fontWeight: "bold",
                    }}
                    className="head"
                  >
                    Upvotes
                  </span>
                  <span
                    style={{
                      color: darkMode ? "white" : "black",
                      fontSize: isMobile ? "20px" : "22px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                    className="point"
                  >
                    {submissionPoints && submissionPoints.total_upvotes
                      ? submissionPoints.total_upvotes
                      : "0"}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 0px",
                  backgroundColor: "transparent",
                  width: "100%",
                  borderStyle: "solid",
                  borderLeftWidth: "0px",
                  borderRightWidth: "1px",
                  borderTopWidth: "1px",
                  borderBottomWidth: "1px",
                  borderColor: "rgba(138, 138, 138,0.7)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 15px 15px 15px",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1px",
                    borderBottomColor: "rgba(138, 138, 138,0.7)",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontSize: isMobile ? "11px" : "13px",
                      fontWeight: "bold",
                    }}
                    className="head"
                  >
                    Published Links
                  </span>
                  <span
                    style={{
                      color: darkMode ? "white" : "black",
                      fontSize: isMobile ? "20px" : "22px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                    className="point"
                  >
                    {submissionPoints && submissionPoints.total_published_link
                      ? submissionPoints.total_published_link
                      : "0"}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px 15px 0px 15px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontSize: isMobile ? "11px" : "13px",
                      fontWeight: "bold",
                    }}
                    className="head"
                  >
                    Super Upvotes
                  </span>
                  <span
                    style={{
                      color: darkMode ? "white" : "black",
                      fontSize: isMobile ? "20px" : "22px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                    className="point"
                  >
                    {submissionPoints && submissionPoints.total_super_votes
                      ? submissionPoints.total_super_votes
                      : "0"}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 0px",
                  backgroundColor: "transparent",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "8px",
                  borderStyle: "solid",
                  borderLeftWidth: "0px",
                  borderRightWidth: "1px",
                  borderTopWidth: "1px",
                  borderBottomWidth: "1px",
                  borderColor: "rgba(138, 138, 138,0.7)",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 15px 15px 15px",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1px",
                    borderBottomColor: "rgba(138, 138, 138,0.7)",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontSize: isMobile ? "11px" : "13px",
                      fontWeight: "bold",
                    }}
                    className="head"
                  >
                    Total Earned
                  </span>
                  <span
                    style={{
                      color: darkMode ? "white" : "black",
                      fontSize: isMobile ? "20px" : "22px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                    className="point"
                  >
                    {submissionPoints && submissionPoints.total_earned
                      ? submissionPoints.total_earned
                      : "0"}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px 15px 0px 15px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontSize: isMobile ? "11px" : "13px",
                      fontWeight: "bold",
                    }}
                    className="head"
                  >
                    Total Spent
                  </span>
                  <span
                    style={{
                      color: darkMode ? "white" : "black",
                      fontSize: isMobile ? "20px" : "22px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                    className="point"
                  >
                    {submissionPoints && submissionPoints.total_spent
                      ? submissionPoints.total_spent
                      : "0"}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`${
                isMobile
                  ? "submission-table-container-mobile"
                  : "submission-table-container"
              }`}
            >
              {/* submission header */}
              <SubmissionTableHeader
                {...props}
                sortBy={sortBy}
                setSortBy={setSortBy}
                filter={filter}
                setFilter={setFilter}
                search={search}
                setSearch={setSearch}
                isMobile={isMobile}
                darkMode={darkMode}
                onDropdownChange={_onDropdownChange}
                dropdownName="Submissions"
                dropdownOptions={dropdownOptions}
                tableType={tableType}
              />
              {/* submission table */}
              <SubmissionTable
                {...props}
                onLinkClick={(id) => {
                  setSubmissionModalData({ showModal: true, id: id });
                }}
                loading={loading}
                activePage={activePage}
                setActivePage={setActivePage}
                submissionData={submissionData}
                isMobile={isMobile}
                darkMode={darkMode}
                tableType={tableType}
              />
              {/* submission pagination */}
              <SubmissionPagination
                {...props}
                itemsPerPage={itemsPerPage}
                activePage={activePage}
                setActivePage={setActivePage}
                submissionData={submissionData}
                totalCount={totalCount}
                darkMode={darkMode}
                isMobile={isMobile}
              />
            </div>
          </div>
        </div>
      </div>
      <SubmissionModal
        onClose={() => {
          setSubmissionModalData({
            showModal: !submissionModalData.showModal,
          });
        }}
        onOpen={() => {
          setSubmissionModalData({
            showModal: !submissionModalData.showModal,
          });
        }}
        linkid={submissionModalData.id}
        open={submissionModalData.showModal}
      />
    </>
  );
}

export default Submissions;
